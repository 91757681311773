import { Component, Input } from '@angular/core';

@Component({
  selector: 'multiple-info-block',
  templateUrl: 'multiple-info-block.html'
})
export class MultipleInfoBlock {
  @Input() label: string;
  @Input() values: any[];
  @Input() valueType?: string;
  @Input() digitsInfo?: string;
}

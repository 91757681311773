import { Component } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {}

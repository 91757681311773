import { EStatusEnvioXmlAnp } from '@core/enums/e-status-envio-xml-anp';
import { handleGetBackgroundByStatusEnvioANP } from './background-status-envio-xml-anp-util';

export function handleGetDisplayStatusEnvioANP(status: EStatusEnvioXmlAnp) {
  if (status) {
    const bgColor = handleGetBackgroundByStatusEnvioANP(status);
    const spanClass = 'budget-status text-sm ' + bgColor;
    return `<span class="${spanClass}">${status}</span>`;
  } else {
    return null;
  }
}

import { ERoutes } from '@core/enums';
import { ETipoEquipamento } from '../enums';

export const configEquipamentosRoutes = new Map<string, any>([
  [ERoutes.EQUIPAMENTO_BALANCA, { title: 'Balança', titleList: 'Balanças', tipo: ETipoEquipamento.BALANCA }],
  [ERoutes.EQUIPAMENTO_BANHO_MARIA, { title: 'Banho Maria', titleList: 'Banho Maria', tipo: ETipoEquipamento.BANHO_MARIA }],
  [ERoutes.EQUIPAMENTO_BEQUER, { title: 'Bequer', titleList: 'Bequers', tipo: ETipoEquipamento.BEQUER }],
  [ERoutes.EQUIPAMENTO_BOMBA_VACUO, { title: 'Bomba Vácuo', titleList: 'Bombas Vácuo', tipo: ETipoEquipamento.BOMBA_VACUO }],
  [ERoutes.EQUIPAMENTO_CENTRIFUGA, { title: 'Centrífuga', titleList: 'Centrífugas', tipo: ETipoEquipamento.CENTRIFUGA }],
  [ERoutes.EQUIPAMENTO_CILINDRO, { title: 'Cilindro', titleList: 'Cilindros', tipo: ETipoEquipamento.CILINDRO }],
  [ERoutes.EQUIPAMENTO_CROMATOGRAFO, { title: 'Cromatógrafo', titleList: 'Cromatógrafos', tipo: ETipoEquipamento.CROMATOGRAFO }],
  [
    ERoutes.EQUIPAMENTO_DENSIMETRO_DIGITAL,
    { title: 'Densímetro Digital', titleList: 'Densímetros Digitais', tipo: ETipoEquipamento.DENSIMETRO_DIGITAL }
  ],
  [ERoutes.EQUIPAMENTO_DENSIMETRO, { title: 'Densímetro', titleList: 'Densímetros', tipo: ETipoEquipamento.DENSIMETRO }],
  [ERoutes.EQUIPAMENTO_ESTUFA, { title: 'Estufa', titleList: 'Estufas', tipo: ETipoEquipamento.ESTUFA }],
  [ERoutes.EQUIPAMENTO_PHMETRO, { title: 'PHmetro', titleList: 'PHmetros', tipo: ETipoEquipamento.PHMETRO }],
  [ERoutes.EQUIPAMENTO_PROVETA, { title: 'Proveta', titleList: 'Provetas', tipo: ETipoEquipamento.PROVETA }],
  [ERoutes.EQUIPAMENTO_SALIMETRO, { title: 'Salímetro', titleList: 'Salímetro', tipo: ETipoEquipamento.SALIMETRO }],
  [ERoutes.EQUIPAMENTO_TERMOMETRO, { title: 'Termômetro', titleList: 'Termômetros', tipo: ETipoEquipamento.TERMOMETRO }],
  [ERoutes.EQUIPAMENTO_TITRANDO, { title: 'Titrando', titleList: 'Titrandos', tipo: ETipoEquipamento.TITRANDO }],
  [ERoutes.EQUIPAMENTO_TITRINO, { title: 'Titrino', titleList: 'Titrinos', tipo: ETipoEquipamento.TITRINO }],
  [ERoutes.EQUIPAMENTO_TUBO, { title: 'Tubo', titleList: 'Tubos', tipo: ETipoEquipamento.TUBO }]
]);

import { HeaderOptions, HeaderOptionsTypes } from '@shared/components';

export const relatorioMedicaoFiscalTanqueItemTableHeader: HeaderOptions[] = [
  {
    description: 'id',
    attribute: 'id'
  },
  {
    description: 'Data e Hora da Medição',
    attribute: 'data_hora_medicao'
  },
  {
    description: 'Altura (mm)',
    attribute: 'altura_total_cm'
  },
  {
    description: 'Volume Total (m³)',
    attribute: 'volume_total_litro',
    type: HeaderOptionsTypes.NUMBER,
    digitsInfo: '1.3-3'
  },
  {
    description: 'Volume Líquido (m³)',
    attribute: 'volume_bruto_corrigido',
    type: HeaderOptionsTypes.NUMBER,
    digitsInfo: '1.3-3'
  },
  {
    description: 'Tipo da Medição',
    attribute: 'tipo_medicao'
  }
];

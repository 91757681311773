<div class="container min-h-full w-full max-w-none bg-white p-8 shadow-sm">
  <header-component>
    <title-component class="my-4">Alterar Senha</title-component>
  </header-component>

  <mat-spinner *ngIf="isLoading; else formBlock"></mat-spinner>

  <ng-template #formBlock>
    <form [formGroup]="alterarSenhaForm" (ngSubmit)="handleSubmit()" class="w-1/2">
      <input-component
        id="senha"
        label="Nova Senha"
        type="password"
        [control]="alterarSenhaForm.get('senha')"
        formControlName="senha"
        [submitted]="isSubmitted"
        required
      ></input-component>
      <h3 class="ml-2 text-base">* Sua senha não pode ser inteiramente numérica.</h3>
      <h3 class="ml-2 text-base">* Sua senha precisa conter pelo menos 8 caracteres.</h3>
      <h3 class="ml-2 text-base">* Sua senha não pode ser uma senha comumente utilizada.</h3>
      <h3 class="ml-2 text-base">* Sua senha não pode ser muito parecida com o resto das suas informações pessoais.</h3>
      <input-component
        id="confirmacao_senha"
        label="Confirmação da Nova Senha"
        type="password"
        [control]="alterarSenhaForm.get('confirmacao_senha')"
        formControlName="confirmacao_senha"
        [submitted]="isSubmitted"
        required
      >
      </input-component>

      <div class="mt-4 flex items-center justify-start gap-4">
        <button type="button" (click)="usuarioService.handleBack()" class="base-btn bg-gray-300 text-gray-700 hover:bg-gray-400">
          <span class="material-icons">chevron_left</span>Voltar
        </button>
        <button type="submit" class="base-btn bg-dark-blue-puple text-white"><span class="material-icons">save</span>Salvar</button>
      </div>
    </form>
  </ng-template>
</div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ERoutes } from '@core/enums';
import { User } from '@core/models';
import { AuthService, CrudErrorService, MessageService } from '@core/services';
import { UsuarioService } from '@features/usuarios/services';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html'
})
export class AlterarSenhaComponent implements OnInit {
  isLoading = false;
  isSubmitted = false;

  loggedUser: User;
  alterarSenhaForm: FormGroup;

  constructor(
    private readonly formBuild: FormBuilder,
    readonly usuarioService: UsuarioService,
    private readonly authService: AuthService,
    private readonly messageService: MessageService,
    private readonly httpErrorService: CrudErrorService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.loggedUser = this.authService.loggedUser();

    this.alterarSenhaForm = this.formBuild.group({
      senha: [null, [Validators.required]],
      confirmacao_senha: [null, [Validators.required]]
    });
  }

  handleSubmit() {
    this.isSubmitted = true;

    if (this.alterarSenhaForm.invalid) {
      return;
    }

    this.isLoading = false;

    const senha = this.alterarSenhaForm.value.senha;
    const senha_repetir = this.alterarSenhaForm.value.confirmacao_senha;

    this.usuarioService.handleAlterarSenha(this.loggedUser.id, { senha, senha_repetir }).subscribe({
      next: () => {
        this.messageService.showSuccess('Senha alterada com sucesso!');
        this.router.navigate([`${ERoutes.PATH_PRIVATE}/${ERoutes.HOME}`]);
      },
      error: (err) => {
        this.isLoading = false;
        this.httpErrorService.handleErrorAPI(err, 'Erro ao alterar senha');
      }
    });
  }
}

<div class="container-principal">
  <mat-spinner *ngIf="isLoading" class="align-center left-2/4 h-6 w-6" [diameter]="25"></mat-spinner>
  <highcharts-chart
    class="container-teste"
    [style.visibility]="isLoading ? 'hidden' : ''"
    class="border-2"
    [Highcharts]="highcharts"
    [(update)]="updateFlag"
    [options]="chartOptions"
    [runOutsideAngular]="true"
    (chartInstance)="onChartInstance($event)"
    style="width: 100%; height: 340px; display: block"
  ></highcharts-chart>
</div>

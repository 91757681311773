<div class="relative w-full rounded border border-gray-600 px-4 py-2.5">
  <span class="absolute -top-2.5 left-2 bg-white px-1 text-sm text-gray-600">{{label}}</span>
  <div *ngFor="let value of values" [title]="value" class="w-full truncate">
    <span>
      {{ (value ? valueType === 'number' ? '• ' + (value | number : digitsInfo ?? '1.4-4' :'pt') : valueType === 'date' ? '• ' + (value |
      date : 'dd/MM/yyyy HH:mm': 'GMT-3') : valueType === 'phone' ? '• ' + (value | phone) : '• ' + value : '-') }}
    </span>
  </div>
  <span *ngIf="values.length === 0">-</span>
</div>

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelecterOption } from '@core/types';
import { compareStrings } from '@core/utils';

const INPUT_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelecterComponent),
  multi: true
};

@Component({
  selector: 'selecter-component',
  templateUrl: 'selecter.component.html',
  providers: [INPUT_FIELD_VALUE_ACCESSOR]
})
export class SelecterComponent implements ControlValueAccessor, AfterViewInit, OnInit, OnChanges {
  @Input() id: string;
  @Input() label: string;
  @Input() options: SelecterOption[];
  @Input() control: AbstractControl;
  @Input() submitted = false;
  @Input() isReadonly = false;
  @Input() miniselect = false;
  @Output() currentValue = new EventEmitter();

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('selectInput') selectInput: ElementRef;

  searchControl = new FormControl();
  selectControl = new FormControl();
  filteredOptions: SelecterOption[];

  isOpen = false;

  private _value: SelecterOption;

  get value() {
    return this._value;
  }

  set value(value: SelecterOption) {
    if (this._value !== value) {
      this._value = value;
      if (this._value && this.value) {
        this.selectControl.setValue(this.value?.label);
      }
      this.onChangeCb(this._value);
    }
  }

  ngOnInit(): void {
    this.filteredOptions = this.options;
    this.searchControl.valueChanges.subscribe((value) => {
      this.filteredOptions = this.options.filter((option) => compareStrings(option.label, value));
    });

    if (this.control.value !== undefined && this.control.value !== null) {
      this.value = this.options.find(
        (option) => String(option.value) === String(this.control.value.value) || String(option.value) === String(this.control.value)
      );

      this.selectControl.setValue(this.value?.label);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.options.length == 0) {
      this.handleClearSelection();
    }
    this.filteredOptions = this.options;
  }

  ngAfterViewInit() {
    this._handleCurrentValue();
  }

  hasValue() {
    return this.value || this.selectControl.value;
  }

  handleClick() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 50);
    }
  }

  handleClickOutside() {
    this.isOpen = false;
    this.searchControl.setValue('');
  }

  handleClickOption(option: SelecterOption) {
    this.value = option;
    this.selectControl.setValue(option.label);
    this.searchControl.setValue('');
    this._handleCurrentValue();
    this.isOpen = false;
  }

  handleClearSelection() {
    this.value = null;
    this.selectControl.setValue('');
    this._handleCurrentValue();
  }

  onChangeCb: (_: any) => void = () => {};
  onTouchedCb: (_: any) => void = () => {};

  writeValue(value: SelecterOption): void {
    this.value = this.options.find((option) => option.value === value);
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  _handleCurrentValue() {
    this.currentValue.emit(this.value);
  }
}

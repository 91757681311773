export enum SidebarModulesName {
  DASHBOARD = 'Dashboard',
  GESTAO_DA_PRODUCAO = 'Gestão da Produção',
  RESERVATORIO = 'Reservatório',
  EQUIPAMENTO = 'Equipamento',
  FECHAMENTO_PRODUCAO = 'Fechamento de Produção',
  LABORATORIO = 'Laboratório',
  ANALISE_QUIMICA = 'Análise Química',
  ADMINISTRACAO = 'Administração',
  CADASTRO = 'Cadastros',
  GESTAO_DE_FLUIDOS = 'Gestão de Fluidos',
  MEDICAO = 'Medições',
  FISCAL = 'Fiscais',
  ESTIMADO = 'Estimados',
  EQUIPAMENTO_LABORATORIO = 'Laboratório',
  EQUIPAMENTO_SUPERFICIE = 'Superfície',
  INSTRUMENTOS = 'Instrumentos',
  INJECOES = 'Injeções',
  INJECOES_DIARIAS = 'Injeções Diárias',
  RELATORIOS = 'Relatórios',
  MEDICAO_FISCAL = 'Medição Fiscal',
  GESTAO_DE_LACRES = 'Gestão de Lacres',
  GESTAO_DE_PRAZOS = 'Gestão de Prazos',
  CATALOGO_OPORTUNIDADES = 'Catálogo de Oportunidades',
  CLASSIFICACAO_DE_PERDAS = 'Classificação de Perdas',
  GRUPOS = 'Grupos',
  TESTE_POCO = 'Teste de Poço',
  GESTAO_CARTAS = 'Gestão de Cartas'
}

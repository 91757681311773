import { ERoutesApi } from '@core/enums';
import { HeaderOptions, HeaderOptionsFilterTypes, HeaderOptionsTypes } from '@shared/components';

export const GestaoCartaEnviadaTableHeader: HeaderOptions[] = [
  {
    description: 'Id',
    attribute: 'id'
  },
  {
    description: 'Número do Documento',
    attribute: 'codigo'
  },
  {
    description: 'Superintendência (Destinatário)',
    attribute: 'destinatario_nome',
    attributeSelectorCheckbox: 'destinatarios_nome',
    typeFilter: HeaderOptionsFilterTypes.SELECTOR_CHECKBOX,
    customRoute: `${ERoutesApi.GESTAO_CARTAS}/opcoes/?coluna=destinatario__nome`
  },
  {
    description: 'Assunto',
    attribute: 'assunto'
  },
  {
    description: 'Data de Envio',
    attribute: 'data_envio',
    type: HeaderOptionsTypes.DATE
  },
  {
    description: 'Carta de Origem',
    attribute: 'carta_origem',
    attributeSelectorCheckbox: 'cartas_origem',
    typeFilter: HeaderOptionsFilterTypes.SELECTOR_CHECKBOX,
    customRoute: `${ERoutesApi.GESTAO_CARTAS}/opcoes/?coluna=carta_origem__codigo`
  },
  {
    description: 'Responsável',
    attribute: 'responsavel_nome',
    attributeSelectorCheckbox: 'responsaveis_nome',
    typeFilter: HeaderOptionsFilterTypes.SELECTOR_CHECKBOX,
    customRoute: `${ERoutesApi.GESTAO_CARTAS}/opcoes/?coluna=responsavel__nome`
  },
  {
    description: 'Status',
    attribute: 'status',
    attributeSelectorCheckbox: 'status',
    typeFilter: HeaderOptionsFilterTypes.SELECTOR_CHECKBOX,
    customRoute: `${ERoutesApi.GESTAO_CARTAS}/opcoes/?coluna=status`
  },
  {
    description: 'Empresa',
    attribute: 'empresa_nome',
    attributeSelectorCheckbox: 'empresas_nome',
    typeFilter: HeaderOptionsFilterTypes.SELECTOR_CHECKBOX,
    customRoute: `${ERoutesApi.GESTAO_CARTAS}/opcoes/?coluna=empresa__razao_social`
  }
];

import { Component, Input } from '@angular/core';

@Component({
  selector: 'info-block',
  templateUrl: 'info-block.html'
})
export class InfoBlock {
  @Input() label: string;
  @Input() value: any;
  @Input() valueType?: string;
  @Input() digitsInfo?: string;
}

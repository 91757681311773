<div mat-dialog-title class="z-[9999] mt-4 flex items-center">
  <span class="mr-2 flex items-center rounded bg-orange-400 px-3 py-1 text-white">
    <span class="material-icons">warning</span>
  </span>
  <h1 class="text-2xl font-medium">{{ data.title }}</h1>
</div>
<div mat-dialog-content [innerHTML]="data.data"></div>
<div mat-dialog-actions class="justify-endc flex items-center">
  <button type="button" class="base-btn bg-gray-200 hover:bg-gray-300" (click)="handleClose()">Fechar</button>
</div>

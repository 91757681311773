import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ERoutes, ERoutesApi } from '@core/enums';
import { ICrudService } from '@core/interfaces';
import { PaginateParams, PaginateResponse, RequestBody } from '@core/types';
import { SelecterOption } from '@shared/forms';
import { map, Observable } from 'rxjs';
import { CrudService } from './crud.service';

class BaseApiModel {
  id?: string | number;
  nome?: string;
  display?: string;
}

@Injectable({ providedIn: 'root' })
export class GenericCrudService<T extends BaseApiModel, BasicModel> implements ICrudService<T, BasicModel> {
  protected readonly httpService = inject(CrudService<any>);
  protected readonly location = inject(Location);
  protected readonly router = inject(Router);

  constructor(private readonly resource: ERoutesApi) {}

  handleFetchById(id: string | number): Observable<T> {
    return this.httpService.getOne(`${this.resource}/${id}`);
  }

  handleFetchPaginate(params: PaginateParams): Observable<PaginateResponse<T>> {
    const { limit, offset, order, query, filters } = params;
    return this.httpService.getWithPaginate(`${this.resource}/${filters}limit=${limit}&offset=${offset}&ordem=${order}&query=${query}`);
  }

  handleFetchWithoutPaginate(params: PaginateParams): Observable<T[]> {
    const { order, query, filters } = params;
    return this.httpService.getWithoutSlash(`${this.resource}/${filters}ordem=${order}&query=${query}`);
  }

  handleFetchOneWithoutPaginate(params: PaginateParams): Observable<T> {
    const { order, query, filters } = params;
    return this.httpService.getOneWithoutSlash(`${this.resource}/${filters}ordem=${order}&query=${query}`);
  }

  handlePostOneWithoutPaginate(params: PaginateParams, body?: RequestBody): Observable<T> {
    const { order, query, filters } = params;

    const formBody: RequestBody = body ?? {};
    formBody['ordem'] = order;

    if (filters) {
      Object.entries(filters).forEach((atributte: any) => {
        const operador = atributte[1]['operator'] ?? '';
        formBody[atributte[1]['column'] + operador] = atributte[1]['value'];
      });
    }

    return this.httpService.postWithoutSlash(`${this.resource}/filtrar/?query=${query}`, formBody);
  }

  handleGetUnidadeEngenhariaDefault(): Observable<string> {
    return this.httpService.getOne(`${ERoutesApi.CONFIGURACOES}/${ERoutesApi.UNIDADE_ENGENHARIA_PADRAO}`);
  }

  handlePostPaginate(params: PaginateParams, body?: RequestBody): Observable<PaginateResponse<T>> {
    const { limit, offset, order, query, filters } = params;

    const formBody: RequestBody = body ?? {};
    formBody['ordem'] = order;

    if (filters) {
      Object.entries(filters).forEach((atributte: any) => {
        const operador = atributte[1]['operator'] ?? '';
        formBody[atributte[1]['column'] + operador] = atributte[1]['value'];
      });
    }

    return this.httpService.postWithoutSlash(`${this.resource}/filtrar/?limit=${limit}&offset=${offset}&query=${query}`, formBody);
  }

  handleSave(model: Partial<BasicModel>): Observable<T> {
    return this.httpService.post(`${this.resource}`, model);
  }

  handleSaveWithFile(model: FormData): Observable<T> {
    return this.httpService.postWithFile(`${this.resource}`, model);
  }

  handleUpdate(model: Partial<BasicModel>, id?: string | number): Observable<T> {
    return this.httpService.put(`${this.resource}/${id}`, model);
  }

  handleUpdateWithPipe(model: Partial<BasicModel>, id?: string | number): Observable<T> {
    return this.httpService.putWithPipe(`${this.resource}/${id}`, model);
  }

  handlePatch(model: Partial<BasicModel>, id?: string | number): Observable<T> {
    return this.httpService.patch(`${this.resource}/${id}`, model);
  }

  handleUpdateWithFile(model: FormData, id?: string | number): Observable<T> {
    return this.httpService.putWithFile(`${this.resource}/${id}`, model);
  }

  handleFetchLikeSelecterOptions(): Observable<SelecterOption[]> {
    return this.httpService
      .get(`${this.resource}`)
      .pipe(map<T[], SelecterOption[]>((values: T[]) => values.map((value) => ({ label: value.display || value.nome, value: value.id }))));
  }

  handleDelete(id: string | number): Observable<void> {
    return this.httpService.delete(`${this.resource}/${id}`).pipe(
      map((value) => {
        return;
      })
    );
  }

  handleBack(path?: string) {
    if (path) {
      return this.router.navigate([path]);
    } else {
      if (history && history.length > 1) {
        return this.location.back();
      } else {
        this.router.navigate([`${ERoutes.PATH_PRIVATE}/${ERoutes.HOME}`]);
      }
    }
  }

  handleTransformBodyInFormData(model: object, attributeFile: string, isFileRemoved?: boolean): FormData {
    const formData = new FormData();

    Object.entries(model).forEach(([attribute, value]) => {
      if ((attribute === attributeFile && value !== '') || attribute !== attributeFile || isFileRemoved) {
        formData.append(attribute, value);
      }
    });

    return formData;
  }

  mapPredicate({ display, id, nome }: BaseApiModel): SelecterOption {
    return { label: display || nome, value: id };
  }
}

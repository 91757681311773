<section [ngClass]="configuration?.class" class="font-sans text-black">
  <h3 [ngClass]="configuration?.titleConfig?.class" *ngIf="configuration?.titleConfig?.isVisible">{{ title }}</h3>
  <hr [ngClass]="configuration?.borderConfig?.class" *ngIf="configuration?.borderConfig?.isVisible" />

  <p *ngIf="dataSources?.length === 0">Nenhum dado encontrado.</p>

  <ng-container *ngFor="let rows of dataSources; let i = index">
    <div [attr.id]="'row-' + (i + 1)" class="my-4 items-start grid grid-cols-{{ maxColumns }}">
      <ng-container *ngFor="let col of rows" class="w-full">
        <p *ngIf="col.isVisible" class="flex flex-wrap items-start">{{ col.label }}</p>
        <span
          *ngIf="col.isVisible"
          class="truncate font-medium"
          [ngClass]="rows.length === 1 && 'col-span-' + (maxColumns > 4 ? maxColumns - 2 : maxColumns - 1)"
          [title]="col | infoBlockDetailPipe"
          >{{ col | infoBlockDetailPipe }} <small *ngIf="col.subvalue" class="ml-2 text-xs font-normal">{{ col.subvalue }}</small></span
        >
      </ng-container>
    </div>
  </ng-container>
  <ng-content></ng-content>
</section>

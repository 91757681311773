import { Injectable } from '@angular/core';
import { ERoutesApi } from '@core/enums';
import { GenericCrudService } from '@core/services';
import { SelecterOption } from '@core/types';
import { Observable, map } from 'rxjs';
import { Empresa } from '../models';

@Injectable({ providedIn: 'root' })
export class EmpresaService extends GenericCrudService<Empresa, Empresa> {
  constructor() {
    super(ERoutesApi.EMPRESAS);
  }

  override handleFetchLikeSelecterOptions(): Observable<SelecterOption[]> {
    return this.httpService
      .get(ERoutesApi.EMPRESAS)
      .pipe(map<Empresa[], SelecterOption[]>((values) => values.map((value) => ({ label: value.razao_social, value: value.id }))));
  }
}

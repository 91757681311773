<div *ngIf="value" class="flex flex-row items-center justify-start">
  <p *ngIf="tipoPressao && tipoPressao?.value !== 'PSI'" class="mr-4 text-sm">
    Valor em psi: <span class="font-semibold">{{ valuePSI ? (valuePSI | number : '1.3-3' : 'pt') : '-' }}</span>
  </p>
  <p *ngIf="tipoPressao && tipoPressao?.value !== 'KPA'" class="mr-4 text-sm">
    Valor em kPa: <span class="font-semibold">{{ valueKPA ? (valueKPA | number : '1.3-3' : 'pt') : '-' }}</span>
  </p>
  <p *ngIf="tipoPressao && tipoPressao?.value !== 'KGF'" class="mr-4 text-sm">
    Valor em kgf/cm²: <span class="font-semibold">{{ valueKGF ? (valueKGF | number : '1.3-3' : 'pt') : '-' }}</span>
  </p>
</div>

import { EStatusEnvioXmlAnp } from '@core/enums/e-status-envio-xml-anp';

export function handleGetBackgroundByStatusEnvioANP(status: EStatusEnvioXmlAnp) {
  const backgrounds = new Map<EStatusEnvioXmlAnp, string>([
    [EStatusEnvioXmlAnp.AGUARDANDO_ENVIO, 'bg-gray-400'],
    [EStatusEnvioXmlAnp.AGUARDANDO_ANALISE, 'bg-warning'],
    [EStatusEnvioXmlAnp.ENVIADO, 'bg-green-700'],
    [EStatusEnvioXmlAnp.INTEGRADO, 'bg-dark-blue-puple'],
    [EStatusEnvioXmlAnp.REJEITADO, 'bg-danger']
  ]);

  return backgrounds.get(status);
}

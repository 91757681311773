<nav
  class="fixed z-20 flex h-[6.5rem] flex-col items-center justify-center bg-white"
  [ngClass]="{
    'w-9/12 xs:w-10/12 sm:w-8/12 md:w-8/12  xl:w-10/12 2xl:w-10/12': isSidebarOpen,
    'w-11/12 xs:w-10/12': !isSidebarOpen,
    'mt-8': envService.environment.value
  }"
  *ngIf="isLoggedUser"
>
  <div class="flex h-full w-full flex-row items-center justify-between px-4">
    <button class="relative rounded bg-slate-200 py-1 pl-1" (click)="handleOpenModalPolo()">
      <div class="flex items-center justify-center px-2">
        <span class="material-icons">apartment</span>
        <span class="font-medium">Polo(s):</span>

        <mat-icon class="mx-2 h-6 w-6" *ngIf="empresasSelecionadasNomes.length === 0"
          ><mat-spinner class="m-0" [diameter]="20"></mat-spinner>
        </mat-icon>

        <span *ngIf="empresasSelecionadasNomes.length > 0" class="mx-1 font-medium">{{ empresasSelecionadasNomes[0] }}</span>
        <span *ngIf="empresasSelecionadasNomes.length > 1" class="font-medium">e {{ empresasSelecionadasNomes[1] }}</span>
        <span *ngIf="empresasSelecionadasNomes.length > 2" class="mx-1 font-medium">e outros</span>
      </div>
    </button>

    <button
      class="relative rounded bg-dark-blue-puple py-1 pl-1"
      (click)="handleOpenOptions()"
      clickOutside
      (clickOutside)="clickedOutside()"
    >
      <div class="flex items-center justify-center gap-2 px-2">
        <span class="font-medium text-white">Olá, {{ user.nome ? user.nome : 'Usuário' }}</span>
        <button
          [title]="isOpenOptions ? 'Fechar' : 'Expandir'"
          type="button"
          class="flex items-center justify-center rounded-full p-1 text-white"
        >
          <span class="material-icons transition-all duration-300" [ngClass]="isOpenOptions ? 'rotate-180' : 'rotate-0'">expand_more</span>
        </button>
      </div>
      <ul class="absolute left-0 w-full rounded-b bg-white text-dark-blue-puple shadow-md" [ngClass]="isOpenOptions ? 'block' : 'hidden'">
        <li
          (click)="handleNavigateToUserProfile()"
          title="Perfil"
          class="flex cursor-pointer items-center justify-start gap-2 p-2 font-light transition-all duration-150 hover:bg-gray-100"
        >
          <span class="material-icons">person</span> Perfil
        </li>
        <li
          routerLink="alterar-senha"
          class="flex cursor-pointer items-center justify-start gap-2 p-2 font-light transition-all duration-150 hover:bg-gray-100"
        >
          <span class="material-icons">sync</span> Alterar senha
        </li>
        <li
          (click)="logout()"
          title="Sair"
          class="flex cursor-pointer items-center justify-start gap-2 p-2 font-light transition-all duration-150 hover:bg-gray-100"
        >
          <span class="material-icons">power_settings_new</span> Logout
        </li>
      </ul>
    </button>
  </div>

  <div class="w-full px-4">
    <hr class="border" />
  </div>
</nav>

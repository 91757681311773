import { Route } from '@angular/router';
import { ERoutes } from '@core/enums';
import { AuthGuard, PublicGuard } from '@core/guards';
import { AlterarSenhaComponent } from '@features/auth/pages/alterar-senha/alterar-senha.component';

export const AppRoutes: Route[] = [
  {
    path: '',
    redirectTo: ERoutes.HOME,
    pathMatch: 'full'
  },
  {
    path: ERoutes.PATH_AUTH,
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [PublicGuard]
  },
  {
    path: ERoutes.ALTERAR_SENHA,
    component: AlterarSenhaComponent,
    title: 'Alterar Senha',
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/estacoes/estacoes.module').then((m) => m.EstacoesModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/empresas/empresas.module').then((m) => m.EmpresasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/cargos/cargos.module').then((m) => m.CargosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/tags/tags.module').then((m) => m.TagsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/campos/campos.module').then((m) => m.CamposModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/perfis/perfis.module').then((m) => m.PerfisModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/zonas-produtora/zonas-produtora.module').then((m) => m.ZonasProdutoraModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/ponto-medicao').then((m) => m.PontoMedicaoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/medicoes-fiscais-linha/medicoes-fiscais-linha.module').then((m) => m.MedicoesFiscaisLinhaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('./features/medicoes-ponto-estimado/medicoes-ponto-estimado.module').then((m) => m.MedicoesPontoEstimadoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('./features/medicoes-pontos-operacionais/medicoes-pontos-operacionais.module').then((m) => m.MedicoesPontosOperacionaisModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/pocos/pocos.module').then((m) => m.PocosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/medicoes-fiscais-tanque').then((m) => m.MedicoesFiscaisTanque),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/injecoes/injecoes.module').then((m) => m.InjecoesModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/colaboradores/colaboradores.module').then((m) => m.ColaboradoresModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/usuarios/usuarios.module').then((m) => m.UsuariosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/testes-poco/testes-poco.module').then((m) => m.TestesPocoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/testes-poco-validacao/testes-poco-validacao.module').then((m) => m.TestesPocoValidacaoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/reservatorios/reservatorios.module').then((m) => m.ReservatoriosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/paradas/paradas.module').then((m) => m.ParadasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('./features/amostra/amostra.module').then((m) => m.AmostraModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/bsws/bsws.module').then((m) => m.BswsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/captacao/captacao.module').then((m) => m.CaptacaoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/cromatrografia/cromatografia.module').then((m) => m.CromatografiaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/densidade/densidade.module').then((m) => m.DensidadeModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/fe&rs/fers.module').then((m) => m.FERSModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/associacao-zonas/associacao-zonas.module').then((m) => m.AssociacaoZonasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/rgos/rgos.module').then((m) => m.RGOSModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/medicoes-agua/medicoes-agua.module').then((m) => m.MedicoesAguaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/consumo-ibu/consumo-ibu.module').then((m) => m.ConsumoIbuModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/producoes-diarias/producoes-diarias.module').then((m) => m.ProducoesDiariasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/injecoes-diarias/injecoes-diarias.module').then((m) => m.InjecoesDiariasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/bmp/bmp.module').then((m) => m.BMPModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/equipamento-laboratorio/equipamento.module').then((m) => m.EquipamentoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/unidade-bombeio/unidades-bombeio.module').then((m) => m.UnidadesBombeioModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/h2s/h2s.module').then((m) => m.H2SModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/salinidade/salinidade.module').then((m) => m.SalinidadeModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/sonologs/sonologs.module').then((m) => m.SonologsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/gas-ventilado/gas-ventilado.module').then((m) => m.GasVentiladoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/acompanhamento-pocos/acompanhamento-pocos.module').then((m) => m.AcompanhamentoPocosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/config-superficie-poco').then((m) => m.ConfigSuperficiePocoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/transformador/transformador.module').then((m) => m.TransformadorModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/cabecote/cabecote.module').then((m) => m.CabecoteModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/fabricante/fabricante.module').then((m) => m.FabricanteModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/pap/pap.module').then((m) => m.PAPModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/relatorio-transferencia-fiscal-tanque/relatorio-transferencia-fiscal-tanque.module').then(
        (m) => m.RelatorioTransferenciaFiscalTanqueModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/cluster/cluster.module').then((m) => m.ClusterModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/instrumento/instrumento.module').then((m) => m.InstrumentoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/lacres/lacres.module').then((m) => m.LacresModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/relatorio-medicao-fiscal/relatorio-medicao-fiscal.module').then((m) => m.RelatorioMedicaoFiscalModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/gestao-falha/gestao-falha.module').then((m) => m.GestaoFalhaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/relatorio-mtbf/relatorio-mtbf.module').then((m) => m.RelatorioMtbfModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/relatorio-perda/relatorio-perda.module').then((m) => m.RelatorioPerdaServiceModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/no/no.module').then((m) => m.NoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/gestao-prazo-teste-poco/gestao-prazo-teste-poco.module').then((m) => m.GestaoPrazoTestePocoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/potencial/potencial.module').then((m) => m.PotencialModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/relatorio-producoes-diarias/relatorio-producoes-diarias.module').then((m) => m.RelatorioProducoesDiariasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/locacao/locacao.module').then((m) => m.LocacaoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/tipos-projetos/tipos-projetos.module').then((m) => m.TiposProjetosModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/pacote-projeto/pacote-projeto.module').then((m) => m.PacoteProjetoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/relatorio-injecoes-diarias/relatorio-injecoes-diarias.module').then((m) => m.RelatorioInjecoesDiariasModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/grupo-perda/grupo-perda.module').then((m) => m.GrupoPerdaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/subgrupo-perda/subgrupo-perda.module').then((m) => m.SubgrupoPerdaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/gestao-prazo-instrumento/gestao-prazo-instrumento.module').then((m) => m.GestaoPrazoInstrumentoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/gestao-prazo-medicao-fiscal-tanque/gestao-prazo-medicao-fiscal-tanque.module').then(
        (m) => m.GestaoPrazoMedicaoFiscalTanqueModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/gestao-prazo-parada/gestao-prazo-parada.module').then((m) => m.GestaoPrazoParadaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/gestao-prazo-medicao-fiscal-linha/gestao-prazo-medicao-fiscal-linha.module').then(
        (m) => m.GestaoPrazoMedicaoFiscalLinhaModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/gestao-prazo-teste-poco-envio/gestao-prazo-teste-poco-envio.module').then((m) => m.GestaoPrazoTestePocoEnvioModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/gestao-prazo-bsw/gestao-prazo-bsw.module').then((m) => m.GestaoPrazoBSWModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/gestao-prazo-fers/gestao-prazo-fers.module').then((m) => m.GestaoPrazoFERSModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/gestao-carta/gestao-carta.module').then((m) => m.GestaoCartaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/superintendencia/superintendencia.module').then((m) => m.SuperintendenciaModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/instalacao-destino-transferencia/instalacao-destino-transferencia.module').then(
        (m) => m.InstalacaoDestinoTransferenciaModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () =>
      import('@features/gestao-prazo-gas-ventilado/gestao-prazo-gas-ventilado.module').then((m) => m.GestaoPrazoGasVentiladoModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/fornecedores/fornecedores.module').then((m) => m.FornecedoresModule),
    canActivate: [AuthGuard]
  },
  {
    path: ERoutes.PATH_PRIVATE,
    loadChildren: () => import('@features/medicoes-operacionais/medicoes-operacionais.module').then((m) => m.MedicoesOperacionaisModule),
    canActivate: [AuthGuard]
  }
];

export enum ETipoEquipamento {
  BALANCA = 'BALANCA',
  BANHO_MARIA = 'BANHO_MARIA',
  BEQUER = 'BEQUER',
  BOMBA_VACUO = 'BOMBA_VACUO',
  CENTRIFUGA = 'CENTRIFUGA',
  CILINDRO = 'CILINDRO',
  CROMATOGRAFO = 'CROMATOGRAFO',
  DENSIMETRO_DIGITAL = 'DENSIMETRO_DIGITAL',
  DENSIMETRO = 'DENSIMETRO',
  ESTUFA = 'ESTUFA',
  PHMETRO = 'PHMETRO',
  PROVETA = 'PROVETA',
  SALIMETRO = 'SALIMETRO',
  TERMOMETRO = 'TERMOMETRO',
  TITRANDO = 'TITRANDO',
  TITRINO = 'TITRINO',
  TUBO = 'TUBO'
}

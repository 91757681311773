import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Changelog } from '@core/models/changelog.model';
import { ChangelogService, CrudErrorService } from '@core/services';

@Component({
  selector: 'app-changelog-modal',
  templateUrl: './changelog-modal.component.html',
  styleUrls: ['./changelog-modal.style.scss']
})
export class ChangelogModalComponent implements OnInit {
  displayedColumns: string[] = ['acoes', 'versao', 'data', 'resumo'];
  changelogs: Changelog[] = null;
  version = '';
  isLoading = false;

  constructor(
    private readonly changelogService: ChangelogService,
    private readonly httpErrorService: CrudErrorService,
    public dialogRef: MatDialogRef<ChangelogModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string }
  ) {}

  ngOnInit() {
    this.isLoading = true;

    this.changelogService.handleFetchChangelogs().subscribe({
      next: (data) => {
        this.changelogs = data;
      },
      error: (err) => {
        this.isLoading = false;
        this.httpErrorService.handleErrorAPI(err, 'Erro ao carregar versões');
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
}

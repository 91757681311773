import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

@Injectable()
export class PaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = $localize`Primeira Página`;
  itemsPerPageLabel = $localize`Itens por página`;

  nextPageLabel = $localize`Próxima página`;
  previousPageLabel = $localize`Página anterior`;
  lastPageLabel = $localize`Ultima página`;

  getRangeLabel(page: number, pageSize: number, length: number) {
    if (length === 0) {
      return $localize`Página 1 de 1`;
    }

    const amountPages = Math.ceil(length / pageSize);
    return $localize`Página ${page + 1} de ${amountPages}`;
  }
}

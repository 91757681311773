const messages: any = {
  //http
  'http.status.0.title': 'Erro',
  'http.status.0.message': 'Não foi possível fazer uma requisição a API!',
  'http.status.401.title': 'Sem autorização!',
  'http.status.401.message': 'Sua sessão expirou, você precisará entrar com suas credenciais novamente',
  'http.status.403': 'Você não tem permissão para efetuar a ação',
  'http.status.404': 'Página não encontrada.',
  'http.status.405': 'Operação não suportada',
  'http.status.500': 'Erro interno no servidor, entre em contato com o suporte.',
  'http.status.undefined': 'Não foi possível se comunicar com os servidores.'
};

export default messages;

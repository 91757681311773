import { Injectable } from '@angular/core';
import { GenericCrudService } from '@core/services';
import { PerfilAPI, Perfil, PermissionGroupAPI } from '../model';
import { ERoutesApi } from '@core/enums';
import { IPerfilService, PerfilFormFetch } from '../adapters';
import { Observable, forkJoin, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PerfilService extends GenericCrudService<PerfilAPI, Perfil> implements IPerfilService {
  constructor() {
    super(ERoutesApi.PERFIS);
  }

  handleFetchPermissionsGroups(): Observable<PermissionGroupAPI[]> {
    return this.httpService.get(ERoutesApi.PERMISSOES);
  }

  handleFetchFormInformation(id?: string | number): Observable<PerfilFormFetch> {
    return forkJoin([this.handleFetchPermissionsGroups(), id ? this.handleFetchById(id) : of(undefined)]).pipe(
      map<[PermissionGroupAPI[], PerfilAPI], PerfilFormFetch>((values) => ({ permissionsGroups: values[0], perfil: values[1] }))
    );
  }
}

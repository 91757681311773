import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { compareStrings } from '@core/utils';
import { SelecterOption } from '@core/types';
import _ from 'lodash';

const INPUT_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultipleSelecterComponent),
  multi: true
};

@Component({
  selector: 'multiple-selecter-component',
  templateUrl: 'multiple-selecter.component.html',
  providers: [INPUT_FIELD_VALUE_ACCESSOR]
})
export class MultipleSelecterComponent implements ControlValueAccessor, AfterViewInit, OnInit {
  @Input() id: string;
  @Input() label: string;
  @Input() options: SelecterOption[];
  @Input() control: AbstractControl;
  @Input() submitted = false;
  @Input() isReadonly = false;
  @Input() selectedOptionsPerColumn = 0;
  @Output() currentValue = new EventEmitter();

  @ViewChild('searchInput') searchInput: ElementRef;

  searchControl = new FormControl();
  selectControl = new FormControl();

  classSelectedOptions = '';
  isOpen = false;

  filteredOptions: SelecterOption[];
  private _values: SelecterOption[] = [];

  get values() {
    return this._values;
  }

  set values(values: SelecterOption[]) {
    this._values = values;
    this.selectControl.setValue(values);
    this.onChangeCb(this._values);
  }

  ngOnInit(): void {
    this.filteredOptions = this.options;
    this.searchControl.valueChanges.subscribe((value) => {
      this.filteredOptions = this.options.filter((option) => compareStrings(option.label, value));
    });
    if (this.selectedOptionsPerColumn > 0) {
      this.classSelectedOptions = `grid-cols-${this.selectedOptionsPerColumn}`;
    }
  }

  ngAfterViewInit() {
    this._handleCurrentValue();
  }

  handleClick() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 50);
    }
  }

  handleClickOutside() {
    this.isOpen = false;
    this.searchControl.setValue('');
  }

  handleClickOption(option: SelecterOption) {
    if (this.values.includes(option)) {
      this.values = _.difference(this.values, [option]);
    } else {
      this.values = _.union(this.values, [option]);
    }
    this._handleCurrentValue();
  }

  handleClickRemoveOption(option: SelecterOption) {
    this.values = _.difference(this.values, [option]);
  }

  handleClearSelection() {
    this.values = [];
    this.selectControl.setValue([]);
    this._handleCurrentValue();
  }

  writeValue(values: any[]): void {
    this.values = _.intersectionBy(
      this.options,
      values.map((value) => ({ value: value, label: '' })),
      'value'
    );
  }

  onChangeCb: (_: any) => void = () => {};
  onTouchedCb: (_: any) => void = () => {};

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  _handleCurrentValue() {
    this.currentValue.emit(this.values);
  }
}

import { ERoutes } from '@core/enums';
import { ETipoInstrumento } from '../enums';

export const configInstrumentoRoutes = new Map<string, any>([
  [
    ERoutes.INSTRUMENTO_COMPUTADOR_VAZAO,
    { title: 'Computador de Vazão', titleList: 'Computadores de Vazão', tipo: ETipoInstrumento.COMPUTADOR_VAZAO }
  ],
  [
    ERoutes.INSTRUMENTO_ELEMENTO_TEMPERATURA,
    { title: 'Elemento de Temperatura', titleList: 'Elementos de Temperatura', tipo: ETipoInstrumento.ELEMENTO_TEMPERATURA }
  ],
  [ERoutes.INSTRUMENTO_MANOMETRO, { title: 'Manômetro', titleList: 'Manômetros', tipo: ETipoInstrumento.MANOMETRO }],

  [
    ERoutes.INSTRUMENTO_MEDIDOR_VAZAO_CORIOLIS,
    {
      title: 'Coriolis',
      titleList: 'Coriolis',
      tipo: ETipoInstrumento.MEDIDOR_VAZAO_CORIOLIS
    }
  ],
  [
    ERoutes.INSTRUMENTO_MEDIDOR_VAZAO_ROTATIVO,
    {
      title: 'Rotativo',
      titleList: 'Rotativo',
      tipo: ETipoInstrumento.MEDIDOR_VAZAO_ROTATIVO
    }
  ],
  [ERoutes.INSTRUMENTO_MVS, { title: 'MVS', titleList: 'MVS', tipo: ETipoInstrumento.MVS }],
  [
    ERoutes.INSTRUMENTO_PLACA_ORIFICIO,
    { title: 'Placa de Orifício', titleList: 'Placas de Orifício', tipo: ETipoInstrumento.PLACA_ORIFICIO }
  ],
  [ERoutes.INSTRUMENTO_REGUA, { title: 'Régua', titleList: 'Réguas', tipo: ETipoInstrumento.REGUA }],
  [ERoutes.INSTRUMENTO_TANQUE, { title: 'Tanque', titleList: 'Tanques', tipo: ETipoInstrumento.TANQUE }],
  [ERoutes.INSTRUMENTO_TERMOMETRO, { title: 'Termômetro', titleList: 'Termômetros', tipo: ETipoInstrumento.TERMOMETRO }],
  [
    ERoutes.INSTRUMENTO_TERMOMETRO_COSTADO_TANQUE,
    {
      title: 'Termômetro do Costado do Tanque',
      titleList: 'Termômetros do Costado do Tanque',
      tipo: ETipoInstrumento.TERMOMETRO_COSTADO_TANQUE
    }
  ],
  [
    ERoutes.INSTRUMENTO_TERMOMETRO_IMERSAO_TANQUE,
    {
      title: 'Termômetro de Imersão do Tanque',
      titleList: 'Termômetros de Imersão do Tanque',
      tipo: ETipoInstrumento.TERMOMETRO_IMERSAO_TANQUE
    }
  ],
  [
    ERoutes.INSTRUMENTO_TRANSMISSOR_DE_NIVEL,
    { title: 'Transmissor de Nível', titleList: 'Transmissores de Nível', tipo: ETipoInstrumento.TRANSMISSOR_DE_NIVEL }
  ],
  [
    ERoutes.INSTRUMENTO_TRANSMISSOR_DIFERENCIAL_PRESSAO,
    {
      title: 'Transmissor de Pressão Diferencial',
      titleList: 'Transmissores de Pressão Diferencial',
      tipo: ETipoInstrumento.TRANSMISSOR_DIFERENCIAL_PRESSAO
    }
  ],
  [
    ERoutes.INSTRUMENTO_TRANSMISSOR_PRESSAO,
    { title: 'Transmissor de Pressão', titleList: 'Transmissores de Pressão', tipo: ETipoInstrumento.TRANSMISSOR_PRESSAO }
  ],
  [
    ERoutes.INSTRUMENTO_TRANSMISSOR_TEMPERATURA,
    {
      title: 'Transmissor de Temperatura',
      titleList: 'Transmissores de Temperatura',
      tipo: ETipoInstrumento.TRANSMISSOR_TEMPERATURA
    }
  ],
  [ERoutes.INSTRUMENTO_TRECHO_RETO, { title: 'Trecho Reto', titleList: 'Trechos Retos', tipo: ETipoInstrumento.TRECHO_RETO }],
  [ERoutes.INSTRUMENTO_TRENA, { title: 'Trena', titleList: 'Trenas', tipo: ETipoInstrumento.TRENA }],
  [ERoutes.INSTRUMENTO_TURBIDIMETRO, { title: 'Turbidímetro', titleList: 'Turbidímetros', tipo: ETipoInstrumento.TURBIDIMETRO }],
  [ERoutes.INSTRUMENTO_VALVULA, { title: 'Válvula', titleList: 'Válvulas', tipo: ETipoInstrumento.VALVULA }]
]);

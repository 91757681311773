import { Component, Input, OnChanges } from '@angular/core';
import { EUnidadeResultado } from '@core/enums';

@Component({
  selector: 'unidade-resultado-calc',
  templateUrl: './unidade-resultado-calc.component.html'
})
export class UnidadeResultadoCalcComponent implements OnChanges {
  @Input() value: string;
  @Input() tipoResultado: any;

  valuePPM = 0;
  valueMG = 0;

  ngOnChanges(): void {
    if (this.value && (this.tipoResultado?.value || this.tipoResultado)) {
      if (this.tipoResultado?.value === EUnidadeResultado.PPM || this.tipoResultado === EUnidadeResultado.PPM) {
        this.valuePPM = Number(this.value.replace(',', '.'));
        this.valueMG = (this.valuePPM * 34.08) / 24.45;
      } else if (this.tipoResultado?.value === EUnidadeResultado.MG || this.tipoResultado === EUnidadeResultado.MG) {
        this.valueMG = Number(this.value.replace(',', '.'));
        this.valuePPM = (this.valueMG * 24.45) / 34.08;
      }
    }
  }
}

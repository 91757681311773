import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-default',
  templateUrl: './dialog-default.component.html'
})
export class DialogDefaultComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogDefaultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; data: HTMLElement }
  ) {}

  handleClose() {
    this.dialogRef.close();
  }
}

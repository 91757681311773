import { HeaderOptions, HeaderOptionsTypes } from '@shared/components';

export const relatorioMedicaoFiscalTanqueTableHeader: HeaderOptions[] = [
  {
    description: 'id',
    attribute: 'id'
  },
  {
    description: 'Status',
    attribute: 'status'
  },
  {
    description: 'Estação',
    attribute: 'estacao'
  },
  {
    description: 'Ponto de Medição',
    attribute: 'ponto_medicao'
  },
  {
    description: 'Tipo de Medição',
    attribute: 'tipo_medicao'
  },
  {
    description: 'Fluido',
    attribute: 'fluido'
  },
  {
    attribute: 'data_hora_inicio',
    description: 'Data e Hora de Início',
    type: HeaderOptionsTypes.DATE_TIME,
    isToFormatValue: false
  },
  {
    attribute: 'data_hora_fim',
    description: 'Data e Hora de Fim',
    type: HeaderOptionsTypes.DATE_TIME,
    isToFormatValue: false
  },
  {
    description: 'Volume Bruto Corrigido (m³)',
    attribute: 'volume_bruto_corrigido',
    type: HeaderOptionsTypes.NUMBER
  },
  {
    description: 'Volume Líquido Corrigido (m³)',
    attribute: 'volume_liquido_corrigido',
    type: HeaderOptionsTypes.NUMBER
  },
  {
    description: 'Data de Criação',
    attribute: 'data_criacao'
  }
];

<ngx-file-drag-drop
  *ngIf="!arquivoAtual && !isOnlyView"
  [id]="id"
  class="m-0 h-28 w-full max-w-full rounded"
  activeBorderColor="#144766"
  emptyPlaceholder="Solte o arquivo ou clique para selecionar"
  [formControl]="fileControl"
  [displayFileSize]="true"
  (valueChanged)="_handleSetFile($event)"
></ngx-file-drag-drop>
<span class="span-required" *ngIf="fileControl.hasError('required') && submitted">* Campo obrigatório</span>

<span *ngIf="!arquivoAtual && isOnlyView">Nenhum arquivo selecionado</span>

<div *ngIf="arquivoAtual" class="flex w-full flex-row items-center justify-start">
  <button
    title="Baixar arquivo"
    class="base-btn rounded-full bg-gray-300 py-1 text-gray-700"
    (click)="_handleDownloadCurrentFile()"
    type="button"
  >
    <span class="material-icons text-xl">download</span> Baixar Arquivo Atual
  </button>
  <button
    *ngIf="!isOnlyView"
    title="Remover arquivo"
    class="button-icon ml-4 text-danger"
    (click)="_handleRemoveCurrentFile()"
    type="button"
  >
    <span class="material-icons text-xl">delete</span>
  </button>
</div>

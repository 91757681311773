<div class="relative">
  <div *ngIf="environment" class="fixed top-0 left-0 right-0 z-40 flex h-8 w-full items-center justify-center bg-red-600">
    <span class="text-xl font-semibold text-white"> {{environment }}</span>
  </div>
  <div *ngIf="isLoggedUser" class="container mx-auto h-screen max-w-none">
    <app-sidebar></app-sidebar>
    <div class="flex h-full flex-row justify-end transition-all duration-150">
      <app-navbar></app-navbar>
      <div
        class="h-full"
        [ngClass]="{
          'w-9/12 xs:w-10/12 sm:w-8/12 md:w-8/12 xl:w-10/12 2xl:w-10/12': isSidebarOpen,
          'w-11/12 xs:w-10/12': !isSidebarOpen,
          'pt-28': environment,
          'pt-20': !environment
        }"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoggedUser">
    <router-outlet></router-outlet>
  </div>
</div>

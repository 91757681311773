import { AfterViewInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService, CrudErrorService, MessageService } from '@core/services';
import { Empresa } from '@features/empresas/models';
import { EmpresaService } from '@features/empresas/services';
import { UsuarioService } from '@features/usuarios/services';
import _ from 'lodash';

@Component({
  selector: 'app-modal-selecao-polo',
  templateUrl: './modal-selecao-polo.component.html'
})
export class ModalSelecaoPoloComponent implements AfterViewInit {
  isLoading = true;
  submitted = false;
  empresasOptions: Empresa[] = [];
  empresasSelected: Empresa[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalSelecaoPoloComponent>,
    private readonly usuarioService: UsuarioService,
    private readonly authService: AuthService,
    private readonly empresaService: EmpresaService,
    private readonly crudErrorService: CrudErrorService,
    private readonly messageService: MessageService
  ) {}

  ngAfterViewInit(): void {
    this.isLoading = true;
    const idsEmpresasColaborador = this.authService.loggedUser().colaborador.empresas.join();

    this.empresaService
      .handlePostPaginate({ limit: 10, offset: 0, order: '', query: '', filters: '' }, { ids: idsEmpresasColaborador })
      .subscribe({
        next: (value) => {
          this.empresasOptions = value.results;
        },
        error: (err) => {
          this.crudErrorService.handleErrorAPI(err, 'Erro ao listar Polos, tente novamente');
          this.dialogRef.close();
        },
        complete: () => {
          this.isLoading = false;
          const idsEmpresasSelectedCache: number[] = this.authService.loggedUser().lista_empresas_selecionadas.map((e) => Number(e));

          const empresasSelectedCache: Empresa[] = _.filter(this.empresasOptions, (empresa) =>
            _.includes(idsEmpresasSelectedCache, Number(empresa.id))
          );

          this.empresasSelected = empresasSelectedCache;
        }
      });
  }

  setAll(checked: boolean) {
    if (checked) {
      this.empresasSelected = _.union(this.empresasSelected, this.empresasOptions);
    } else {
      this.empresasSelected = _.difference(this.empresasSelected, this.empresasOptions);
    }
  }

  setOne(checked: boolean, empresaSelected: Empresa) {
    if (checked) {
      this.empresasSelected.push(empresaSelected);
    } else {
      this.empresasSelected = this.empresasSelected.filter((empresa) => String(empresa.id) !== String(empresaSelected.id));
    }
  }

  isCheckedAll() {
    return _.every(this.empresasOptions, (empresa) => _.includes(this.empresasSelected, empresa));
  }

  isCheckedOne(value: Empresa) {
    return this.empresasSelected.find((v) => v === value) !== undefined ? true : false;
  }

  handleConfirm() {
    this.submitted = true;

    if (this.empresasSelected.length === 0) {
      this.messageService.showAlert('Selecione pelo menos um polo');
      return;
    }

    const empresasSelecionadasIds = this.empresasSelected.map((empresa) => empresa.id);

    this.usuarioService.handleAlterarEmpresasSelecionadas(this.authService.loggedUser().id, empresasSelecionadasIds).subscribe({
      next: (value) => {
        this.authService.handleSetUsuario(value);
        this.authService.handleSetEmpresasSelecionadasNomes(this.empresasSelected.map((empresa) => empresa.nome_fantasia));
      },
      error: (err) => {
        this.crudErrorService.handleErrorAPI(err, 'Erro ao salvar visualização por Polo');
      },
      complete: () => {
        this.messageService.showSuccess('Visualização por Polo salva com sucesso');
        this.dialogRef.close();
        window.location.reload();
      }
    });
  }

  handleCancel() {
    this.dialogRef.close();
  }
}

import { Component, Input } from '@angular/core';
import { EFileType } from '@core/enums/e-file-type';
import { CrudErrorService, FilterService } from '@core/services';
import { DownloadFileService } from '@core/services/download-file.service';
import { MessageService } from '@core/services/message.service';

@Component({
  selector: 'app-export-table',
  templateUrl: './export-table.component.html',
  styleUrls: ['./export-table.style.scss']
})
export class ExportTableComponent {
  @Input() fileName?: string;
  @Input() path: string;
  @Input() pathCustomized?: boolean = false;
  @Input() bodyParams?: Map<string, string>;
  @Input() queryParams?: Map<string, string>;
  @Input() rotaParamsCache?: string = '';
  @Input() atributeSelectionParamsCache?: string = '';
  @Input() hasFilters = false;

  isDownloading = false;
  EtypeFile = EFileType;
  bodyParamsCache: Map<string, string> = new Map<string, string>();
  queryParamsToSend: Map<string, string> = new Map<string, string>();

  constructor(
    private service: DownloadFileService,
    private messageService: MessageService,
    private errorService: CrudErrorService,
    private filterService: FilterService
  ) {}

  handleClick() {
    this.hasFilters =
      this.filterService.recoverFilter(this.rotaParamsCache) &&
      (this.filterService.recoverFilter(this.rotaParamsCache)?.otherFilters?.length > 0 ||
        this.filterService.recoverFilter(this.rotaParamsCache)?.query != '');
  }

  download(type: EFileType, isFiltrado: boolean) {
    const filterCache = this.filterService.recoverFilter(this.rotaParamsCache);

    if (this.bodyParams) {
      this.bodyParamsCache.clear();
      this.bodyParamsCache = new Map(this.bodyParams);
    }

    if (this.queryParams) {
      this.queryParamsToSend.clear();
      this.queryParamsToSend = new Map(this.queryParams);
    }

    if (this.rotaParamsCache) {
      filterCache?.order ? this.bodyParamsCache.set('ordem', filterCache.order) : '';

      if (isFiltrado) {
        filterCache.query ? this.queryParamsToSend.set('query', filterCache.query) : '';
        filterCache.selection.length > 0
          ? this.bodyParamsCache.set(this.atributeSelectionParamsCache, filterCache.selection.toString())
          : '';

        filterCache.otherFilters
          ? Object.entries(filterCache.otherFilters).forEach((filtro) => {
              const operador = filtro[1]['operator'] ?? '';
              this.bodyParamsCache.set(filtro[1]['column'] + operador, filtro[1]['value']);
            })
          : '';
      }
    }

    this.isDownloading = true;
    const bodyParams = Object.fromEntries(this.bodyParamsCache);

    this.service.downloadFileDefault(this.path, this.pathCustomized, this.queryParamsToSend, bodyParams).subscribe({
      next: (value) => {
        this.service.saveFile(this.fileName, type, value.blob);
      },
      error: (err) => {
        this.isDownloading = false;
        this.errorService.handleErrorAPI(err, 'Erro ao baixar arquivo');
      },
      complete: () => {
        this.isDownloading = false;
        this.messageService.showSuccess('', 'Arquivo baixado com sucesso');
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { EAguaVapor, EPermissions, ERoutes } from '@core/enums';
import { configEquipamentosRoutes } from '@features/equipamento-laboratorio/constantes/config-equipamentos-routes';
import { ETipoCarta } from '@features/gestao-carta/utils';
import { configInstrumentoRoutes } from '@features/instrumento/constantes';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  permissionsByRoute: Map<string, EPermissions[]>;

  constructor(private readonly authService: AuthService) {
    this.initPermissionsByRoute();
  }

  /**
   * Verifica se o usuário logado possui uma determinada permissão.
   *
   * @param {EPermissions} permission a permissão a ser verifica
   * @returns {boolean} true caso o usuário possua a permissão desejada
   */

  userHasPermission(permission: EPermissions): boolean {
    const loggedUser = this.authService.loggedUser();

    if (!loggedUser) {
      return false;
    } else {
      if (loggedUser.is_superuser) {
        return true;
      } else {
        const permissions = loggedUser.permissoes;
        return permissions.find((p) => p === permission) !== undefined ? true : false;
      }
    }
  }

  userHasPermissions(permissions: EPermissions[]): boolean {
    const loggedUser = this.authService.loggedUser();
    let allow = false;

    if (!loggedUser) {
      allow = false;
    } else {
      if (loggedUser.is_superuser) {
        allow = true;
      } else {
        const permissionsUser: EPermissions[] = loggedUser.permissoes;
        permissions.forEach((p) => {
          if (permissionsUser.includes(p)) {
            allow = true;
          }
        });
      }
    }
    return allow;
  }

  userHasPermissionRoute(url: string): boolean {
    const loggedUser = this.authService.loggedUser();
    let allow = false;

    if (loggedUser) {
      url = url.replace(/[0-9]/g, '');

      const permissionsUser: EPermissions[] = loggedUser.permissoes;

      if (this.permissionsByRoute.has(url)) {
        const permissionsRoute: EPermissions[] = this.permissionsByRoute.get(url);

        if (loggedUser.is_superuser) {
          allow = true;
        } else if (permissionsUser != null && permissionsUser.length > 0) {
          permissionsUser.forEach((permissionUser) => {
            if (permissionsRoute.includes(permissionUser)) {
              allow = true;
            }
          });
        }
      } else {
        allow = true;
      }
    }

    return allow;
  }

  // prettier-ignore
  private initPermissionsByRoute() {
    this.permissionsByRoute = new Map<string, EPermissions[]>();

    /**
     *
     * Permissões da aba
     * GESTÃO DA PRODUÇÃO
     *
     */
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAMPO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_CAMPO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAMPO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_CAMPO]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAMPO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_CAMPO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAMPO}`, [EPermissions.VISUALIZAR_CAMPO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.ESTACAO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_ESTACAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.ESTACAO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_ESTACAO]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.ESTACAO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_ESTACAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.ESTACAO}`, [EPermissions.VISUALIZAR_ESTACAO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.POCO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.POCO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.POCO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.POCO}/${ERoutes.CARACTERISTICA}/`, [EPermissions.SALVAR_CARACTERISTICA_POCO, EPermissions.ENCERRAR_CARACTERISTICA_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.POCO}/${ERoutes.CARACTERISTICA}/${ERoutes.EDITAR}//`, []);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.POCO}`, [EPermissions.VISUALIZAR_POCO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CLUSTER}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_CLUSTER]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CLUSTER}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_CLUSTER]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CLUSTER}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_CLUSTER]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CLUSTER}`, [EPermissions.VISUALIZAR_CLUSTER]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAPTACAO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_CAPTACAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAPTACAO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_CAPTACAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAPTACAO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_CAPTACAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CAPTACAO}`, [EPermissions.VISUALIZAR_CAPTACAO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.INJECAO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_INJECAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.INJECAO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_INJECAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.INJECAO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_INJECAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.INJECAO}`, [EPermissions.VISUALIZAR_INJECAO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_FISCAL_LINHA_GAS}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_MEDICAO_FISCAL_LINHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_FISCAL_LINHA_GAS}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_MEDICAO_FISCAL_LINHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_FISCAL_LINHA_GAS}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_MEDICAO_FISCAL_LINHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_FISCAL_LINHA_GAS}`, [EPermissions.VISUALIZAR_MEDICAO_FISCAL_LINHA]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_FISCAL_LINHA_OLEO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_MEDICAO_FISCAL_LINHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_FISCAL_LINHA_OLEO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_MEDICAO_FISCAL_LINHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_FISCAL_LINHA_OLEO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_MEDICAO_FISCAL_LINHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_FISCAL_LINHA_OLEO}`, [EPermissions.VISUALIZAR_MEDICAO_FISCAL_LINHA]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CICLOS}/`, [EPermissions.CADASTRAR_MEDICAO_FISCAL_TANQUE, EPermissions.EDITAR_MEDICAO_FISCAL_TANQUE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_FISCAL_TANQUE}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_MEDICAO_FISCAL_TANQUE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CICLOS}/`, [EPermissions.VISUALIZAR_MEDICAO_FISCAL_TANQUE]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_AGUA}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_MEDICAO_AGUA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_AGUA}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_MEDICAO_AGUA]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_AGUA}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_MEDICAO_AGUA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.MEDICAO_AGUA}`, [EPermissions.VISUALIZAR_MEDICAO_AGUA]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GAS_VENTILADO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_BOLETIM_GAS_VENTILADO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GAS_VENTILADO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_BOLETIM_GAS_VENTILADO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GAS_VENTILADO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_BOLETIM_GAS_VENTILADO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GAS_VENTILADO}`, [EPermissions.VISUALIZAR_BOLETIM_GAS_VENTILADO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CONSUMO_IBU}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_CONSUMO_PARA_MEDICAO_IBU]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CONSUMO_IBU}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_CONSUMO_PARA_MEDICAO_IBU]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CONSUMO_IBU}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_CONSUMO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CONSUMO_IBU}`, [EPermissions.VISUALIZAR_CONSUMO_IBU]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PARADA}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_PARADA_DE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PARADA}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_PARADA_DE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PARADA}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_PARADA_DE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PARADA}`, [EPermissions.VISUALIZAR_PARADA_DE_POCO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SONOLOG}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_SONOLOG]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SONOLOG}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_SONOLOG]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SONOLOG}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_SONOLOG]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SONOLOG}/${ERoutes.IMPORTAR}`, [EPermissions.IMPORTAR_PLANILHA_SONOLOG]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SONOLOG}`, [EPermissions.VISUALIZAR_SONOLOG]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_FALHAS}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_FALHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_FALHAS}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_FALHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_FALHAS}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_FALHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_FALHAS}/${ERoutes.DIAGNOSTICAR}/`, [EPermissions.EDITAR_FALHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_FALHAS}`, [EPermissions.VISUALIZAR_FALHA]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TESTE_POCO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_TESTE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TESTE_POCO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_TESTE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TESTE_POCO}/${ERoutes.VALIDAR}/`, [EPermissions.VALIDAR_TESTE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TESTE_POCO}/${ERoutes.RELATORIO}/`, [EPermissions.GERAR_RELATORIO_TESTE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TESTE_POCO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_TESTE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TESTE_POCO}`, [EPermissions.VISUALIZAR_TESTE_POCO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CONFIGURACAO_SUPERFICIE_POCO}`, [EPermissions.VISUALIZAR_CONFIG_SUPERFICIE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CONFIGURACAO_SUPERFICIE_POCO}/${ERoutes.DETALHAR}`, [EPermissions.VISUALIZAR_CONFIG_SUPERFICIE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CONFIGURACAO_SUPERFICIE_POCO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_CONFIG_SUPERFICIE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CONFIGURACAO_SUPERFICIE_POCO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_CONFIG_SUPERFICIE_POCO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.NO}`, [EPermissions.VISUALIZAR_NO])
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.NO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_NO])
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.NO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_NO])

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.INSTALACAO_DESTINO_TRANSFERENCIA}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_INSTALACAO_DESTINO_TRANSFERENCIA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.INSTALACAO_DESTINO_TRANSFERENCIA}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_INSTALACAO_DESTINO_TRANSFERENCIA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.INSTALACAO_DESTINO_TRANSFERENCIA}`, [EPermissions.VISUALIZAR_INSTALACAO_DESTINO_TRANSFERENCIA]);


    /**
     *
     * Permissões da aba
     * Fechamento de Produção
     *
     */
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PRODUCAO_DIARIA}`, [EPermissions.PRODUCAO_DIARIA_DE_PRODUCAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.INJECAO_DIARIA}/`, [EPermissions.INJECAO_DIARIA_DE_PRODUCAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.BMP}`, [EPermissions.GERAR_BMP]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.BMP}/${ERoutes.CONSOLIDACAO}`, [EPermissions.CONSOLIDAR_BMP]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.ACOMPANHAMENTO_POCO}`, [EPermissions.ACOMPANHAMENTO_POCO]);


    /**
     *
     * Permissões da aba
     * RELATÓRIOS
     *
     */
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RELATORIO}/${ERoutes.TRANSFERENCIA_FISCAL_TANQUE}`, [EPermissions.GERAR_RELATORIO_TRANSFERENCIA_FISCAL_TANQUE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RELATORIO}/${ERoutes.TRANSFERENCIA_FISCAL_TANQUE}/${ERoutes.DETALHAR}`, [EPermissions.GERAR_RELATORIO_TRANSFERENCIA_FISCAL_TANQUE]);


    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RELATORIO}/${ERoutes.MEDICAO_FISCAL}/`, [EPermissions.GERAR_RELATORIO_MEDICAO_FISCAL]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RELATORIO}/${ERoutes.MEDICAO_FISCAL}/${ERoutes.DETALHAR}`, [EPermissions.GERAR_RELATORIO_MEDICAO_FISCAL]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RELATORIO}/${ERoutes.MTBF}`, [EPermissions.GERAR_RELATORIO_MTBF]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RELATORIO}/${ERoutes.PERDA}/`, [EPermissions.GERAR_RELATORIO_PERDA]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RELATORIO}/${ERoutes.PRODUCAO_DIARIA}/`, [EPermissions.PRODUCAO_DIARIA_DE_PRODUCAO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RELATORIO}/${ERoutes.INJECAO_DIARIA}/${EAguaVapor.AGUA}/`, [EPermissions.INJECAO_DIARIA_DE_PRODUCAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RELATORIO}/${ERoutes.INJECAO_DIARIA}${EAguaVapor.VAPOR}/`, [EPermissions.INJECAO_DIARIA_DE_PRODUCAO]);


    /**
     *
     * Permissões da aba
     * GESTÃO DE PRAZOS
     *
     */
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_PRAZOS}/${ERoutes.TESTE_POCO}`, [EPermissions.GERAR_RELATORIO_GESTAO_PRAZO_TESTE_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_PRAZOS}/${ERoutes.INSTRUMENTO}`, [EPermissions.GERAR_RELATORIO_GESTAO_PRAZO_INSTRUMENTO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_PRAZOS}/${ERoutes.GAS_VENTILADO}`, [EPermissions.GERAR_RELATORIO_GESTAO_PRAZO_GAS_VENTILADO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_PRAZOS}/${ERoutes.MEDICAO_FISCAL_TANQUE}`, [EPermissions.GERAR_RELATORIO_GESTAO_PRAZO_MEDICAO_TANQUE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_PRAZOS}/${ERoutes.MEDICAO_FISCAL_LINHA_GAS}`, [EPermissions.GERAR_RELATORIO_GESTAO_PRAZO_MEDICAO_LINHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_PRAZOS}/${ERoutes.MEDICAO_FISCAL_LINHA_OLEO}`, [EPermissions.GERAR_RELATORIO_GESTAO_PRAZO_MEDICAO_LINHA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_PRAZOS}/${ERoutes.PARADA}`, [EPermissions.GERAR_RELATORIO_GESTAO_PRAZO_PARADA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_PRAZOS}/${ERoutes.BSW}`, [EPermissions.GERAR_RELATORIO_GESTAO_PRAZO_BSW]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_PRAZOS}/${ERoutes.FERS}`, [EPermissions.GERAR_RELATORIO_GESTAO_PRAZO_FERS]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SUPERINTENDENCIA}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_SUPERINTENDENCIA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SUPERINTENDENCIA}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_SUPERINTENDENCIA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SUPERINTENDENCIA}`, [EPermissions.VISUALIZAR_SUPERINTENDENCIA]);


     /**
     *
     * Permissões da aba
     * GESTÃO DE CARTAS
     *
     */
     this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_CARTA}/${ETipoCarta.ENVIADA}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_GESTAO_CARTA]);
     this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_CARTA}/${ETipoCarta.ENVIADA}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_GESTAO_CARTA]);
     this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_CARTA}/${ETipoCarta.ENVIADA}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_GESTAO_CARTA]);
     this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_CARTA}/${ETipoCarta.ENVIADA}`, [EPermissions.VISUALIZAR_GESTAO_CARTA]);

     this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_CARTA}/${ETipoCarta.RECEBIDA}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_GESTAO_CARTA]);
     this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_CARTA}/${ETipoCarta.RECEBIDA}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_GESTAO_CARTA]);
     this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_CARTA}/${ETipoCarta.RECEBIDA}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_GESTAO_CARTA]);
     this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_CARTA}/${ETipoCarta.RECEBIDA}`, [EPermissions.VISUALIZAR_GESTAO_CARTA]);


    /**
     *
     * Permissões da aba
     * RESERVATÓRIO
     *
     */
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PAP}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_PAP]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PAP}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_PAP]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PAP}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_PAP]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PAP}`, [EPermissions.VISUALIZAR_PAP]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RESERVATORIO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_RESERVATORIO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RESERVATORIO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_RESERVATORIO]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RESERVATORIO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_RESERVATORIO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RESERVATORIO}`, [EPermissions.VISUALIZAR_RESERVATORIO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RGO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_RGO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RGO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_RGO]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RGO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_RGO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.RGO}`, [EPermissions.VISUALIZAR_RGO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.ZONA_PRODUTORA}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_ZONA_PRODUTORA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.ZONA_PRODUTORA}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_ZONA_PRODUTORA]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.ZONA_PRODUTORA}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_ZONA_PRODUTORA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.ZONA_PRODUTORA}`, [EPermissions.VISUALIZAR_ZONA_PRODUTORA]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.POCO_ZONA}/${ERoutes.CRIAR}`, [EPermissions.VINCULAR_ZONA_AO_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.POCO_ZONA}/${ERoutes.EDITAR}/`, [EPermissions.VINCULAR_ZONA_AO_POCO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.POCO_ZONA}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_ZONA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.POCO_ZONA}`, [EPermissions.VISUALIZAR_ZONA]);


    /**
     *
     * Permissões da aba
     * EQUIPAMENTOS
     *
     */
    configEquipamentosRoutes.forEach((value, rota) => {
      this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${rota}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_EQUIPAMENTO]);
      this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${rota}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_EQUIPAMENTO]);
      this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${rota}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_EQUIPAMENTO]);
      this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${rota}`, [EPermissions.VISUALIZAR_EQUIPAMENTO]);
    });

    configInstrumentoRoutes.forEach((value, rota) => {
      this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${rota}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_INSTRUMENTO]);
      this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${rota}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_INSTRUMENTO]);
      this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${rota}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_INSTRUMENTO]);
      this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${rota}`, [EPermissions.VISUALIZAR_INSTRUMENTO]);
    });

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.FABRICANTE}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_FABRICANTE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.FABRICANTE}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_FABRICANTE]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.FABRICANTE}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_FABRICANTE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.FABRICANTE}`, [EPermissions.VISUALIZAR_FABRICANTE]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CABECOTE}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_CABECOTE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CABECOTE}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_CABECOTE]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CABECOTE}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_CABECOTE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CABECOTE}`, [EPermissions.VISUALIZAR_CABECOTE]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TRANSFORMADOR}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_TRANSFORMADOR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TRANSFORMADOR}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_TRANSFORMADOR]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TRANSFORMADOR}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_TRANSFORMADOR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TRANSFORMADOR}`, [EPermissions.VISUALIZAR_TRANSFORMADOR]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.UNIDADE_BOMBEIO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_UNIDADE_BOMBEIO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.UNIDADE_BOMBEIO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_UNIDADE_BOMBEIO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.UNIDADE_BOMBEIO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_UNIDADE_BOMBEIO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.UNIDADE_BOMBEIO}`, [EPermissions.VISUALIZAR_UNIDADE_BOMBEIO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TAG}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_TAG]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TAG}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_TAG]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TAG}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_TAG]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TAG}`, [EPermissions.VISUALIZAR_TAG]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PONTO_MEDICAO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_PONTO_MEDICAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PONTO_MEDICAO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_PONTO_MEDICAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PONTO_MEDICAO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_PONTO_MEDICAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PONTO_MEDICAO}`, [EPermissions.VISUALIZAR_PONTO_MEDICAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PONTO_MEDICAO}/${ERoutes.ALTERAR_INSTRUMENTO}/`, [EPermissions.ALTERAR_INSTRUMENTO_PONTO_MEDICAO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_LACRES}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_LOTE])
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_LACRES}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_LOTE])
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_LACRES}`, [EPermissions.VISUALIZAR_LOTE])

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_LACRES}/${ERoutes.INSTALACAO_REMOCAO}/${ERoutes.INSTALAR}`, [EPermissions.CADASTRAR_LACRE])
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_LACRES}/${ERoutes.INSTALACAO_REMOCAO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_LACRE])
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_LACRES}/${ERoutes.INSTALACAO_REMOCAO}/${ERoutes.REMOVER}/`, [EPermissions.REMOVER_LACRE])
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_LACRES}/${ERoutes.INSTALACAO_REMOCAO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_LACRE])
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.GESTAO_LACRES}/${ERoutes.INSTALACAO_REMOCAO}`, [EPermissions.VISUALIZAR_LACRE])

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.FORNECEDORES}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_FORNECEDOR])
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.FORNECEDORES}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_FORNECEDOR])
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.FORNECEDORES}`, [EPermissions.VISUALIZAR_FORNECEDOR])


    /**
     *
     * Permissões da aba
     * LABORATÓRIO
     *
     */
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.AMOSTRA}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_AMOSTRA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.AMOSTRA}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_AMOSTRA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.AMOSTRA}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_AMOSTRA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.AMOSTRA}`, [EPermissions.VISUALIZAR_AMOSTRA]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.BSW}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_BSW]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.BSW}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_BSW]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.BSW}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_BSW]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.BSW}`, [EPermissions.VISUALIZAR_BSW]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CROMATOGRAFIA}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_CROMATOGRAFIA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CROMATOGRAFIA}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_CROMATOGRAFIA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CROMATOGRAFIA}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_CROMATOGRAFIA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CROMATOGRAFIA}`, [EPermissions.VISUALIZAR_CROMATOGRAFIA]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.DENSIDADE}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_DENSIDADE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.DENSIDADE}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_DENSIDADE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.DENSIDADE}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_DENSIDADE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.DENSIDADE}`, [EPermissions.VISUALIZAR_DENSIDADE]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.FERS}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_FERS]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.FERS}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_FERS]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.FERS}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_FERS]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.FERS}`, [EPermissions.VISUALIZAR_FERS]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.H2S}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_H2S]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.H2S}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_H2S]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.H2S}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_H2S]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.H2S}`, [EPermissions.VISUALIZAR_H2S]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SALINIDADE}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_SALINIDADE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SALINIDADE}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_SALINIDADE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SALINIDADE}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_SALINIDADE]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.SALINIDADE}`, [EPermissions.VISUALIZAR_SALINIDADE]);


    /**
     *
     * Permissões da aba
     * ADMINISTRAÇÃO
     *
     */
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CARGO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_CARGO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CARGO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_CARGO]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CARGO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_CARGO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.CARGO}`, [EPermissions.VISUALIZAR_CARGO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.COLABORADOR}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_COLABORADOR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.COLABORADOR}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_COLABORADOR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.COLABORADOR}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_COLABORADOR]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.COLABORADOR}`, [EPermissions.VISUALIZAR_COLABORADOR]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.EMPRESA}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_EMPRESA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.EMPRESA}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_EMPRESA]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.EMPRESA}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_EMPRESA]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.EMPRESA}`, [EPermissions.VISUALIZAR_EMPRESA]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PERFIL}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_PERFIL]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PERFIL}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_PERFIL]);
    // this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PERFIL}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_PERFIL]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PERFIL}`, [EPermissions.VISUALIZAR_PERFIL]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.USUARIO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_USUARIO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.USUARIO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_USUARIO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.USUARIO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_USUARIO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.USUARIO}`, [EPermissions.VISUALIZAR_USUARIO]);


    /**
     * Catálogo de Oportunidades
     */
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TIPO_PROJETO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_TIPO_PROJETO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TIPO_PROJETO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_TIPO_PROJETO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TIPO_PROJETO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_TIPO_PROJETO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.TIPO_PROJETO}`, [EPermissions.VISUALIZAR_TIPO_PROJETO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.LOCACAO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_LOCACAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.LOCACAO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_LOCACAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.LOCACAO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_LOCACAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.LOCACAO}`, [EPermissions.VISUALIZAR_LOCACAO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PACOTE_PROJETO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_PACOTE_PROJETO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PACOTE_PROJETO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_PACOTE_PROJETO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PACOTE_PROJETO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_PACOTE_PROJETO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.PACOTE_PROJETO}`, [EPermissions.VISUALIZAR_PACOTE_PROJETO]);

    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.LOCACAO}/${ERoutes.CRIAR}`, [EPermissions.CADASTRAR_LOCACAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.LOCACAO}/${ERoutes.EDITAR}/`, [EPermissions.EDITAR_LOCACAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.LOCACAO}/${ERoutes.DETALHAR}/`, [EPermissions.VISUALIZAR_LOCACAO]);
    this.permissionsByRoute.set(`${ERoutes.PATH_PRIVATE}/${ERoutes.LOCACAO}`, [EPermissions.VISUALIZAR_LOCACAO]);
  }
}

import { Component, DoCheck, OnInit } from '@angular/core';
import { EEnvironment } from '@core/enums/e-environment';
import { AuthService, EnvService, SidebarService } from '@core/services';

@Component({
  selector: 'app-layout',
  templateUrl: 'app-layout.html'
})
export class AppLayout implements DoCheck, OnInit {
  isSidebarOpen: boolean;
  isLoggedUser = false;
  environment: EEnvironment;

  constructor(
    private readonly sidebarService: SidebarService,
    private readonly authService: AuthService,
    private readonly envService: EnvService
  ) {}

  ngDoCheck(): void {
    this.isSidebarOpen = this.sidebarService.handleGetSidebarConfig()?.isOpen;
    this.isLoggedUser = this.authService.isLoggedUser();
    this.environment = this.envService.environment.value;
  }

  ngOnInit(): void {
    this.envService.getEnvironment();
    this.isSidebarOpen = this.sidebarService.handleGetSidebarConfig()?.isOpen;
    this.isLoggedUser = this.authService.isLoggedUser();
  }
}

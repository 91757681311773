<div mat-dialog-title class="mt-4 flex items-center">
  <span class="mr-2 flex items-center rounded bg-dark-blue-puple px-3 py-1 text-white"> <span class="material-icons">apartment</span></span>
  <h1 class="text-2xl">Visualização por Polo</h1>
</div>

<mat-spinner *ngIf="isLoading; else selecaoPoco"></mat-spinner>

<ng-template #selecaoPoco>
  <div mat-dialog-content class="flex flex-col justify-between">
    <p *ngIf="empresasOptions.length === 0" class="ml-3 mb-4 text-lg font-medium text-black">Nenhuma empresa encontrada</p>

    <div *ngIf="empresasOptions.length > 0">
      <mat-checkbox color="primary" [checked]="isCheckedAll()" (change)="setAll($event.checked)">Selecionar Todos</mat-checkbox>
      <mat-divider></mat-divider>

      <ul class="mt-2 h-32 overflow-y-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300">
        <li *ngFor="let empresa of empresasOptions">
          <mat-checkbox
            [id]="empresa.id.toString()"
            color="primary"
            class="tex-sm"
            [checked]="isCheckedOne(empresa)"
            (change)="setOne($event.checked, empresa)"
          >
            {{ empresa.nome_fantasia }}
          </mat-checkbox>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<div mat-dialog-actions class="flex items-center gap-4 px-6 py-4">
  <button type="button" class="base-btn bg-gray-200 hover:bg-gray-300" (click)="handleCancel()">Cancelar</button>
  <button type="button" class="base-btn bg-dark-blue-puple text-white" (click)="handleConfirm()">Salvar</button>
</div>

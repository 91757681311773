import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.style.scss']
})
export class CustomPaginatorComponent implements OnInit, OnChanges {
  inputValue: number = 1;
  pagesCount: number = 1;
  debounceValue: number = 1000;
  inputSubject: Subject<number> = new Subject<number>();
  placeholder: string = '1';
  isMoreThenMin: boolean = false;

  @Input() pageOptions = [10, 15, 20, 25, 30];
  @Input() paginaAtual: number;
  @Input() totalItems: number;
  @Input() pageSize: number;
  @Output() onChangePage = new EventEmitter<PageEvent>();

  ngOnInit(): void {
    this.pagesCount = Math.ceil(this.totalItems / this.pageSize);
    this.inputValue = this.paginaAtual + 1;

    this.inputSubject
      .asObservable()
      .pipe(debounceTime(this.debounceValue))
      .subscribe(() => this.handleInputChange());
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.pagesCount = Math.ceil(this.totalItems / this.pageSize);
    this.inputValue = this.paginaAtual + 1;
    this.placeholder = (this.paginaAtual + 1).toString();
    this.isMoreThenMin = this.totalItems > this.pageOptions[0];
  }

  handleInputChange() {
    if (this.inputValido()) {
      const paginaAnterior = this.paginaAtual;

      const page: PageEvent = {
        pageIndex: --this.inputValue,
        previousPageIndex: paginaAnterior,
        pageSize: this.pageSize,
        length: this.totalItems
      };

      this.onChangePage.emit(page);
    }
  }

  handleItemsPerPage() {
    const page: PageEvent = {
      pageIndex: 0,
      pageSize: this.pageSize,
      length: this.totalItems
    };

    this.onChangePage.emit(page);
  }

  handleNextOrPreviousPage(isPrevious: boolean): void {
    const paginaAnterior = this.paginaAtual;
    isPrevious ? this.paginaAtual-- : this.paginaAtual++;

    const page: PageEvent = {
      pageIndex: this.paginaAtual,
      previousPageIndex: paginaAnterior,
      pageSize: this.pageSize,
      length: this.totalItems
    };

    this.onChangePage.emit(page);
  }

  _handleChange() {
    this.inputSubject.next(this.inputValue);
  }

  private inputValido(): boolean {
    return this.inputValue <= Math.ceil(this.totalItems / this.pageSize) && this.inputValue > 0 && this.inputValue - 1 != this.paginaAtual;
  }
}

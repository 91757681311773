import { DatePipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DataSource, DataType } from '@core/types';
import { PhonePipe } from './phone.pipe';

@Pipe({ name: 'infoBlockDetailPipe' })
export class InfoBlockDetailPipe implements PipeTransform {
  maskByTypeMap = new Map<DataType, string>([
    ['date', 'dd/MM/yyy'],
    ['date-time', 'dd/MM/yyy HH:mm'],
    ['date-time-seconds', 'dd/MM/yyy HH:mm:ss'],
    ['date-time-milliseconds', 'dd/MM/yyy HH:mm:ss.SSS']
  ]);

  transform(value: DataSource) {
    if (!value?.value) {
      return '-';
    }
    if (value.type === 'number') {
      return new DecimalPipe('pt').transform(value.value, value.digitsInfo);
    }
    if (value.type === 'phone') {
      return new PhonePipe().transform(value.value);
    }
    if (value.type === 'text' || value.type === 'text-area') {
      return value.value;
    }
    return new DatePipe('pt').transform(value.value, this.maskByTypeMap.get(value.type));
  }
}

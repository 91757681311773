import { Injectable } from '@angular/core';
import { ERoutesApi } from '@core/enums';
import { IChangelogService } from '@core/interfaces';
import { Changelog } from '@core/models/changelog.model';
import { CrudService } from '@core/services';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChangelogService implements IChangelogService {
  constructor(private readonly changelogService: CrudService<Changelog>) {}

  handleFetchVersion(): Observable<any> {
    return this.changelogService.getOneWithoutSlash(`${ERoutesApi.CHANGELOG}/versao_atual/`);
  }

  handleFetchChangelogs(): Observable<any> {
    return this.changelogService.getOneWithoutSlash(`${ERoutesApi.CHANGELOG}/log/`);
  }
}

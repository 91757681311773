import { Component, Input, OnChanges } from '@angular/core';
import { EUnidadePressao } from '@core/enums';

@Component({
  selector: 'unidade-pressao-calc',
  templateUrl: './unidade-pressao-calc.component.html'
})
export class UnidadePressaoCalcComponent implements OnChanges {
  @Input() value: string;
  @Input() tipoPressao: any;

  valuePSI = 0;
  valueKPA = 0;
  valueKGF = 0;

  ngOnChanges(): void {
    if (this.value && (this.tipoPressao?.value || this.tipoPressao)) {
      if (this.tipoPressao?.value === EUnidadePressao.PSI || this.tipoPressao === EUnidadePressao.PSI) {
        this.valuePSI = Number(this.value.replace(',', '.'));
        this.valueKPA = this.valuePSI / 0.145037738;
        this.valueKGF = this.valuePSI * 0.070307;
      } else if (this.tipoPressao?.value === EUnidadePressao.KPA || this.tipoPressao === EUnidadePressao.KPA) {
        this.valueKPA = Number(this.value.replace(',', '.'));
        this.valuePSI = this.valueKPA * 0.145037738;
        this.valueKGF = this.valueKPA * 0.010197162;
      } else if (this.tipoPressao?.value === EUnidadePressao.KGF || this.tipoPressao === EUnidadePressao.KGF) {
        this.valueKGF = Number(this.value.replace(',', '.'));
        this.valuePSI = this.valueKGF * 14.223;
        this.valueKPA = this.valueKGF * 98.0665;
      }
    }
  }
}

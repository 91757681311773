import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as Highcharts from 'highcharts';
import 'highcharts/modules/series-label';

@Component({
  selector: 'chart-line',
  templateUrl: './chart-line.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./chart-line.style.scss']
})
export class ChartLineComponent implements OnInit {
  @Input() title: string;
  @Input() labelX: string;
  @Input() labelY: string;

  isLoading: boolean = false;
  highcharts: typeof Highcharts = Highcharts;
  updateFlag: boolean = false;
  chartOptions: Highcharts.Options = {};
  chart: Highcharts.Chart;

  constructor() {}

  onChartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
  }

  ngOnInit(): void {
    this.chartOptions = {
      chart: {
        type: 'line',
        marginTop: 35
      },
      title: {
        text: this.title
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical'
      },
      xAxis: {
        title: {
          text: this.labelX
        },
        type: 'category',
        labels: {
          step: 1,
          rotation: -30
        }
      },
      credits: { enabled: false },
      yAxis: {
        title: {
          text: this.labelY
        }
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: false
        }
      },
      series: []
    };
  }

  update(data?: any[]): void {
    this.isLoading = true;
    this.clearChart();
    data.forEach((value) => {
      this.chart.addSeries(value);
    });
    this.updateFlag = true;
    this.isLoading = false;
  }

  clearChart() {
    while (this.chart.series.length) {
      this.chart.series[0].remove();
    }
  }
}

<div *ngIf="isMoreThenMin" class="mt-4 flex w-full items-center justify-end">
  <div class="flex flex-row items-center gap-1">
    <span>Itens por página</span>

    <mat-form-field class="mx-2 mr-10 w-24" appearance="outline">
      <mat-select class="text-sm" [(ngModel)]="pageSize" (ngModelChange)="handleItemsPerPage()">
        <mat-option *ngFor="let pageSize of pageOptions" [value]="pageSize">{{ pageSize }}</mat-option>
      </mat-select>
    </mat-form-field>

    <span>Página</span>

    <mat-form-field class="mx-2 w-24" appearance="outline">
      <input
        matInput
        type="number"
        class="input-base text-sm placeholder:text-gray-400 placeholder:opacity-80"
        min="1"
        [max]="pagesCount"
        [placeholder]="placeholder"
        [(ngModel)]="inputValue"
        (ngModelChange)="_handleChange()"
        id="pagina"
        name="pagina"
        autocomplete="off"
      />
    </mat-form-field>
  </div>

  <span class="mr-10 ml-2">{{ ' de ' + pagesCount }}</span>

  <button
    type="button"
    class="button-icon mr-2 text-slate-500 disabled:text-slate-100 hover:rounded-full hover:bg-neutral-100 disabled:hover:bg-white"
    [disabled]="paginaAtual <= 0"
    title="Página Anterior"
    (click)="handleNextOrPreviousPage(true)"
  >
    <span class="material-icons text-3xl">chevron_left</span>
  </button>

  <button
    type="button"
    class="button-icon text-slate-500 disabled:text-slate-100 hover:rounded-full hover:bg-neutral-100 disabled:hover:bg-white"
    [disabled]="paginaAtual >= pagesCount - 1"
    title="Próxima página"
    (click)="handleNextOrPreviousPage(false)"
  >
    <span class="material-icons text-3xl">chevron_right</span>
  </button>
</div>

import { Component, DoCheck, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangelogModalComponent, SidebarConfiguration, SidebarModuleRoutingType, SidebarModulesName } from '@core/components';
import { AuthService, ChangelogService, CrudErrorService, EnvService, SidebarService } from '@core/services';
import { FilterService } from '@core/services/filter.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements DoCheck, OnInit {
  sidebarConfig: SidebarConfiguration;
  version = '';

  selectedModule: SidebarModulesName = null;
  selectedSubModule: SidebarModulesName = null;
  selectedSubModuleInside: SidebarModulesName = null;

  isLoggedUser = false;
  isExpandMenu = false;

  routes: SidebarModuleRoutingType[] = [];
  filteredRoutes: SidebarModuleRoutingType[] = [];
  textSearch = '';

  constructor(
    private readonly sidebarService: SidebarService,
    private readonly filtroService: FilterService,
    private readonly authService: AuthService,
    private readonly changelogService: ChangelogService,
    private readonly httpErrorService: CrudErrorService,
    private readonly dialog: MatDialog,
    readonly envService: EnvService
  ) {}

  ngOnInit(): void {
    this.routes = this.sidebarService.getRoutes();
    this.filteredRoutes = this.sidebarService.getRoutes();
    this.handleGetVersion();

    this.sidebarConfig = this.sidebarService.handleGetSidebarConfig();

    if (this.sidebarConfig) {
      this.selectedModule = this.sidebarConfig.lastTabOpen;
    } else {
      this.sidebarService.handleSetSidebarConfig({ isOpen: false, lastTabOpen: this.selectedModule });
      this.sidebarConfig = this.sidebarService.handleGetSidebarConfig();
    }
  }

  ngDoCheck() {
    this.isLoggedUser = this.authService.isLoggedUser();
    const sidebarConfigLocalStorage = this.sidebarService.handleGetSidebarConfig();

    if (sidebarConfigLocalStorage !== this.sidebarConfig) {
      this.sidebarConfig = sidebarConfigLocalStorage;
    }

    if (this.textSearch) {
      this.filteredRoutes = this.sidebarService.validateSearch(this.routes, this.textSearch);
    } else {
      this.filteredRoutes = this.routes;
    }
  }

  handleSidebar() {
    this.sidebarService.handleSetSidebarConfig({ isOpen: !this.sidebarConfig.isOpen, lastTabOpen: this.selectedModule });
  }

  handleIconSidebar(option: SidebarModuleRoutingType) {
    if (option.hasTopics || option.hasSubtopics) {
      this.handleClickModule(option.moduleName);
    } else {
      this.handleClickModule(null);
    }
    this.handleSidebar();
  }

  handleClickModule(moduleName: SidebarModulesName) {
    if (this.selectedModule === moduleName) {
      this.selectedModule = null;
    } else {
      this.selectedModule = moduleName;
    }

    this.selectedSubModule = null;

    this.sidebarConfig.lastTabOpen = this.selectedModule;
    this.sidebarService.handleSetSidebarConfig(this.sidebarConfig);
  }

  handleClickSubModule(moduleName: SidebarModulesName) {
    if (this.selectedSubModule === moduleName) {
      this.selectedSubModule = null;
    } else {
      this.selectedSubModule = moduleName;
    }
  }

  handleClickSubModuleInside(moduleName: SidebarModulesName) {
    if (this.selectedSubModuleInside === moduleName) {
      this.selectedSubModuleInside = null;
    } else {
      this.selectedSubModuleInside = moduleName;
    }
  }

  clickedOutside() {
    if (this.sidebarConfig.isOpen) {
      this.sidebarConfig.isOpen = false;
      this.sidebarService.handleSetSidebarConfig(this.sidebarConfig);
    }
  }

  handleCleanFilter(rota: string) {
    this.filtroService.removeFiltro(rota);
  }

  handleGetVersion() {
    this.changelogService.handleFetchVersion().subscribe({
      next: (data) => {
        this.version = data;
      },
      error: (err) => {
        this.httpErrorService.handleErrorAPI(err, 'Erro ao bucar versão atual do sistema');
      }
    });
  }

  handleOpenVersion() {
    this.dialog.open(ChangelogModalComponent, {
      width: '60%',
      height: '80%',
      data: { title: 'Versões Lançadas' }
    });
  }
}

import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EFileExtensions, ERoutesApi } from '@core/enums';
import { EFileType } from '@core/enums/e-file-type';
import { CrudService } from '@core/services/crud.service';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { map } from 'rxjs';

const PROPERTY_HEADER_FILE_NAME = 'filename';
const FILE_NAME_DEFAULT = 'arquivo';

@Injectable({ providedIn: 'root' })
export class DownloadFileService {
  fileNameDefault: string;

  constructor(private service: CrudService<any>) {}

  downloadFile(path: string, filename?: string) {
    return this.service.getFile(path).pipe(
      map((response: HttpResponse<Blob>) => {
        if (filename != undefined) {
          this.fileNameDefault = filename;
        } else if (response.headers.has(PROPERTY_HEADER_FILE_NAME)) {
          this.fileNameDefault = response.headers.get(PROPERTY_HEADER_FILE_NAME);
        } else {
          this.fileNameDefault = FILE_NAME_DEFAULT;
        }
        return { filename: this.fileNameDefault, blob: response.body };
      })
    );
  }

  downloadFileDefault(path: string, pathCustomized: boolean, queryParams?: Map<string, string>, bodyParams?: Partial<any>) {
    this.fileNameDefault = path;
    const fullPath = this._getFullPath(path, pathCustomized, queryParams);
    return this.service.postFile(fullPath, null, bodyParams).pipe(
      map((response: HttpResponse<Blob>) => {
        return { filename: this.fileNameDefault, blob: response.body };
      })
    );
  }

  saveFile(fileName: string, type: EFileType, file: Blob) {
    const fullName = this._getFullName(fileName, type);
    saveAs(file, fullName);
  }

  saveFileWithoutExtension(fileName: string, file: Blob) {
    saveAs(file, fileName);
  }

  private _getFullName(fileName: string, type: EFileType) {
    const fullName = this._addExtension(fileName ? fileName : `${this.fileNameDefault}_${format(Date.now(), 'dd-MM-yyyy_HH-mm')}`, type);
    return fullName;
  }

  private _addExtension(fileName: string, type: EFileType) {
    const extension = type === EFileType.PDF ? EFileExtensions.PDF : type === EFileType.EXCEL ? EFileExtensions.XLSX : EFileExtensions.CSV;
    return `${fileName}${extension}`;
  }

  private _getFullPath(path: string, pathCustomized: boolean, queryParams?: Map<string, string>) {
    return this._addParameters(pathCustomized ? `${path}` : `${path}/${ERoutesApi.EXPORT_CSV}`, queryParams);
  }

  private _addParameters(path: string, queryParams?: Map<string, string>) {
    let parameters = '';
    if (queryParams) {
      parameters =
        '?' +
        Array.from(queryParams.entries())
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
    }
    return `${path}/${parameters}`;
  }
}

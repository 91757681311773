import { Pipe, PipeTransform } from '@angular/core';
import { SelecterOption } from '@core/types';

@Pipe({ name: 'filterEquipamento' })
export class FilterEquipamentoPipe implements PipeTransform {
  transform(items: SelecterOption[], filter: string | string[]): any {
    if (!items || !filter) {
      return items;
    }
    if (Array.isArray(filter)) {
      return items.filter((e) => filter.includes(e.tipo));
    }
    return items.filter((e) => e.tipo === filter);
  }
}

import { Component, DoCheck, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ERoutes } from '@core/enums';
import { User } from '@core/models';
import { AuthService, EnvService, SidebarService } from '@core/services';
import { ModalSelecaoPoloComponent } from '../modal-selecao-polo';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements DoCheck, OnInit {
  isLoggedUser = false;
  user: User = null;
  isOpenOptions = false;
  isSidebarOpen: boolean;
  empresasSelecionadasNomes: string[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly sidebarService: SidebarService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    readonly envService: EnvService
  ) {}

  ngOnInit() {
    this.isOpenOptions = false;
    this.isSidebarOpen = this.sidebarService.handleGetSidebarConfig().isOpen;
  }

  ngDoCheck(): void {
    this.user = this.authService.loggedUser();
    this.isLoggedUser = this.authService.isLoggedUser();
    this.isSidebarOpen = this.sidebarService.handleGetSidebarConfig().isOpen;
    this.empresasSelecionadasNomes = this.authService.handleGetEmpresasSelecionadasNomes() ?? [];
  }

  logout(): void {
    this.isOpenOptions = false;
    this.authService.signOut();
  }

  handleOpenOptions() {
    this.isOpenOptions = !this.isOpenOptions;
  }

  clickedOutside(): void {
    if (this.isOpenOptions) {
      this.isOpenOptions = false;
    }
  }

  handleNavigateToUserProfile() {
    const currentLoggedUser = this.authService.loggedUser();
    this.router.navigate([`${ERoutes.USUARIO}/${ERoutes.PERFIL}`, currentLoggedUser.id]);
  }

  handleOpenModalPolo() {
    this.dialog.open(ModalSelecaoPoloComponent, { width: '40%' });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ERoutesApi } from '@core/enums';
import { EEnvironment } from '@core/enums/e-environment';
import { Environment } from '@core/models';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CrudErrorService } from '.';

@Injectable({ providedIn: 'root' })
export class EnvService {
  private readonly _baseUrl = `${environment.apiUrl}/${ERoutesApi.USUARIOS}/${ERoutesApi.AMBIENTE}/`;
  environment = new BehaviorSubject<EEnvironment>(null);

  constructor(private readonly httpService: HttpClient, private readonly crudErrorService: CrudErrorService) {}

  getEnvironment() {
    this.httpService.get<Environment>(this._baseUrl).subscribe({
      next: (environment) => {
        const env = environment.dev ? EEnvironment.DEV : environment.homol ? EEnvironment.HOMOL : null;
        this.environment.next(env);
      },
      error: (err) => {
        this.crudErrorService.handleErrorAPI(err, 'Erro ao buscar ambiente');
      }
    });
  }
}

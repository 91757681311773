import { Injectable } from '@angular/core';
import { SidebarConfiguration } from '@core/components';

const TOKEN = 'token';
const SIDEBAR_CONFIG = 'sidebarConfig';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  getToken(): string | null {
    return localStorage.getItem(TOKEN);
  }

  getSidebarConfig() {
    const config = localStorage.getItem(SIDEBAR_CONFIG);
    return JSON.parse(config) as unknown as SidebarConfiguration;
  }

  saveSidebarConfig(sidebarConfig: SidebarConfiguration) {
    localStorage.setItem(SIDEBAR_CONFIG, JSON.stringify(sidebarConfig));
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { DataSource } from '@core/types';
import { InfoBlockDetailConfig } from './info-block-detail.config';

@Component({
  selector: 'app-info-block-detail',
  templateUrl: 'info-block-detail.component.html'
})
export class InfoBlockDetailComponent implements OnInit {
  @Input() title?: string;
  @Input() dataSources?: DataSource[][];
  @Input() class?: string;
  @Input() classTitle?: string;

  @Input() configuration?: InfoBlockDetailConfig = {
    borderConfig: {
      isVisible: true,
      class: 'mb-4 border-b'
    },
    titleConfig: {
      isVisible: true,
      class: 'text-lg'
    },
    isVisible: true,
    class: 'mb-8 w-9/12'
  };

  maxColumns = 0;

  ngOnInit(): void {
    this.maxColumns = this.maxColumnsDataSource;
    // para não quebrar estilizações já existentes
    if (this.class) {
      this.configuration.class = this.class;
    }
    if (this.classTitle) {
      this.configuration.titleConfig.class = this.classTitle;
    }
  }

  get maxColumnsDataSource() {
    return this.dataSources?.reduce((max, row) => Math.max(max, row.length), 0) * 2 ?? 0;
  }
}

import { ERoutesApi } from '@core/enums';
import { HeaderOptions, HeaderOptionsFilterTypes, HeaderOptionsTypes } from '@shared/components';

export const GestaoCartaRecebidaTableHeader: HeaderOptions[] = [
  {
    description: 'Prazo de Resposta',
    attribute: 'prazo_resposta_display',
    type: HeaderOptionsTypes.NUMBER,
    isToFormatValue: false
  },
  {
    description: 'Id',
    attribute: 'id'
  },
  {
    description: 'Número do Documento',
    attribute: 'codigo'
  },
  {
    description: 'Superintendência (Remetente)',
    attribute: 'remetente_nome',
    attributeSelectorCheckbox: 'remetentes_nome',
    typeFilter: HeaderOptionsFilterTypes.SELECTOR_CHECKBOX,
    customRoute: `${ERoutesApi.GESTAO_CARTAS}/opcoes/?coluna=remetente__nome`
  },
  {
    description: 'Assunto',
    attribute: 'assunto'
  },
  {
    description: 'Data de Recebimento',
    attribute: 'data_recebimento',
    type: HeaderOptionsTypes.DATE
  },
  {
    description: 'Data de Resposta',
    attribute: 'data_resposta',
    type: HeaderOptionsTypes.DATE
  },
  {
    description: 'Exige Resposta',
    attribute: 'exige_resposta'
  },
  {
    description: 'Responsável',
    attribute: 'responsavel_nome',
    attributeSelectorCheckbox: 'responsaveis_nome',
    typeFilter: HeaderOptionsFilterTypes.SELECTOR_CHECKBOX,
    customRoute: `${ERoutesApi.GESTAO_CARTAS}/opcoes/?coluna=responsavel__nome`
  },
  {
    description: 'Status',
    attribute: 'status',
    attributeSelectorCheckbox: 'status',
    typeFilter: HeaderOptionsFilterTypes.SELECTOR_CHECKBOX,
    customRoute: `${ERoutesApi.GESTAO_CARTAS}/opcoes/?coluna=status`
  },
  {
    description: 'Empresa',
    attribute: 'empresa_nome',
    attributeSelectorCheckbox: 'empresas_nome',
    typeFilter: HeaderOptionsFilterTypes.SELECTOR_CHECKBOX,
    customRoute: `${ERoutesApi.GESTAO_CARTAS}/opcoes/?coluna=empresa__razao_social`
  }
];

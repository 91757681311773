<button
  type="button"
  (click)="handleClick()"
  class="base-btn bg-dark-blue-puple text-white"
  [matMenuTriggerFor]="menu"
  title="Exportar"
  [disabled]="isDownloading"
>
  <span class="material-icons">download</span>Exportar
  <mat-icon class="h-6 w-6" *ngIf="isDownloading"
    ><mat-spinner class="icon-snniper-export-table" color="primary" [diameter]="20"></mat-spinner
  ></mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button [disabled]="!hasFilters" class="base-btn text-dark-blue-puple disabled:text-gray-400" (click)="download(EtypeFile.CSV, true)">
    <span class="material-icons">filter_list </span> Dados Filtrados
  </button>
  <button class="base-btn text-dark-blue-puple" (click)="download(EtypeFile.CSV, false)">
    <span class="material-icons">list_alt </span> Todos os Dados
  </button>
</mat-menu>
